import React from 'react';
import { View } from 'react-native';
import { BaseColor } from '../../config/theme';
import FormTextInput from './FormTextInput';
import FormDropdownInput from './FormDropdownInput';
import FormCheckBoxInput from './FormCheckBoxInput';
import FormUploaderInput from './FormUploaderInput';

function Section({ navigation, section, setSection, editable }) {
    const renderInput = () => {
        return (
            <View>
                {section.submitedInputs?.map((submitedInput, index) => {
                    let Component;
                    switch (submitedInput.input?.type) {
                        case 'Text':
                            Component = FormTextInput;
                            break;
                        case 'Dropdown':
                            Component = FormDropdownInput;
                            break;
                        case 'CheckBox':
                            Component = FormCheckBoxInput;
                            break;
                        case 'Uploader':
                            Component = FormUploaderInput;
                            break;
                        default:
                            break;
                    }
                    return (
                        <View key={submitedInput.id}>
                            <Component
                                editable={editable}
                                isLast={index == section.submitedInputs?.length - 1}
                                submitedInput={submitedInput}
                                navigation={navigation}
                                setInput={(newInput) => {
                                    setSection({
                                        submitedInputs: section.submitedInputs?.map((i) => (i.id == submitedInput.id ? { ...i, ...newInput } : i)),
                                    });
                                }}
                            />
                        </View>
                    );
                })}
            </View>
        );
    };

    return (
        <View
            style={{
                marginBottom: 15,
                backgroundColor: BaseColor.whiteColor,
                borderBottomColor: BaseColor.grayTransparent,
                borderBottomWidth: 1,
            }}
        >
            {renderInput()}
        </View>
    );
}

export default Section;
