export const actionConstants = {
    GETALL_REQUEST: 'ACTION_GETALL_REQUEST',
    GETALL_SUCCESS: 'ACTION_GETALL_SUCCESS',
    GETALL_FAILURE: 'ACTION_GETALL_FAILURE',

    GETBYID_REQUEST: 'ACTION_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'ACTION_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ACTION_GETBYID_FAILURE',

    DELETE_REQUEST: 'ACTION_DELETE_REQUEST',
    DELETE_SUCCESS: 'ACTION_DELETE_SUCCESS',
    DELETE_FAILURE: 'ACTION_DELETE_FAILURE',

    CREATE_REQUEST: 'ACTION_CREATE_REQUEST',
    CREATE_SUCCESS: 'ACTION_CREATE_SUCCESS',
    CREATE_FAILURE: 'ACTION_CREATE_FAILURE',

    UPDATE_REQUEST: 'ACTION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ACTION_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ACTION_UPDATE_FAILURE',

    LOGOUT_SUCCESS: 'ACTION_LOGOUT_SUCCESS',
    CHANGE_CURRENT: 'ACTION_CHANGE_CURRENT',
};
