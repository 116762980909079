import { Platform, StyleSheet } from 'react-native';
import { BaseColor } from './theme';

/**
 * Common basic style defines
 */
export const BaseStyle = StyleSheet.create({
    textInput: {
        height: 40,
        borderRadius: 30,
        paddingHorizontal: 10,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    textInputArea: {
        minHeight: 60,
        maxHeight: 100,
        borderRadius: 30,
        paddingHorizontal: 10,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    safeAreaView: {
        flex: 1,
    },
    boxWithShadow: {
        shadowColor: '#00000066',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 5,
    },
    boxWithShadow1: {
        shadowColor: '#00000066',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 10,
    },
    boxWithShadowBottom: Platform.OS=="android" ?{ shadowColor: '#00000099', elevation: 10 }:{borderBottomWidth:1, borderBottomColor:BaseColor.fieldColor},
});
