import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { Typography, FontWeight, useTheme, useFont } from '@config';
import { BaseColor } from '../../config';

const Roboto = {
    100: 'Thin',
    200: 'Thin',
    300: 'Light',
    400: 'Regular',
    500: 'Medium',
    600: 'Medium',
    700: 'Bold',
    800: 'Bold',
    900: 'Black',
    normal: 'Regular',
    bold: 'Bold',
};

export default function Index(props) {
    const {
        //props style
        header,
        title1,
        title2,
        title3,
        headline,
        body1,
        body2,
        callout,
        callout1,
        subhead,
        subhead1,
        footnote,
        caption1,
        caption2,
        overline,
        // props font
        thin,
        ultraLight,
        light,
        regular,
        medium,
        semibold,
        bold,
        heavy,
        black,
        //custom color
        primaryColor,
        firstColor,
        darkPrimaryColor,
        lightPrimaryColor,
        accentColor,
        grayColor,
        dividerColor,
        whiteColor,
        fieldColor,
        //numberOfLines
        numberOfLines,
        textAlign,
        errorColor,
        successColor,
        //custom
        style,
        //children
        children,
        onPress,
        blueColor,
    } = props;

    const { colors } = useTheme();
    const font = useFont();

    let textStyle = StyleSheet.flatten([
        { fontFamily: font, textAlign },
        {
            fontSize: 15,
        },
        header && Typography.header,
        title1 && Typography.title1,
        title2 && Typography.title2,
        title3 && Typography.title3,
        headline && Typography.headline,
        body1 && Typography.body1,
        body2 && Typography.body2,
        callout && Typography.callout,
        callout1 && Typography.callout1,
        subhead && Typography.subhead,
        subhead1 && Typography.subhead1,
        footnote && Typography.footnote,
        caption1 && Typography.caption1,
        caption2 && Typography.caption2,
        overline && Typography.overline,
        //custom for font
        thin && { fontWeight: FontWeight.thin },
        thin && { fontWeight: FontWeight.thin },
        ultraLight && { fontWeight: FontWeight.ultraLight },
        light && { fontWeight: FontWeight.light },
        regular && { fontWeight: FontWeight.regular },
        medium && { fontWeight: FontWeight.medium },
        semibold && { fontWeight: FontWeight.semibold },
        bold && { fontWeight: FontWeight.bold },
        heavy && { fontWeight: FontWeight.heavy },
        black && { fontWeight: FontWeight.black },
        // default color
        { color: BaseColor.text },
        //custom for color
        firstColor && { color: colors.first },
        primaryColor && { color: colors.primary },
        darkPrimaryColor && { color: colors.primaryDark },
        lightPrimaryColor && { color: colors.primaryLight },
        accentColor && { color: colors.accent },
        grayColor && { color: BaseColor.grayColor },
        dividerColor && { color: BaseColor.dividerColor },
        whiteColor && { color: BaseColor.whiteColor },
        fieldColor && { color: BaseColor.fieldColor },
        errorColor && { color: BaseColor.errorColor },
        successColor && { color: BaseColor.greenDark },
        blueColor && { color: BaseColor.blueColor },
        style && style,
    ]);

    if (textStyle.fontFamily) {
        const fontWeight = textStyle.fontWeight ?? 400;
        textStyle.fontFamily = `${textStyle.fontFamily}-${Roboto[fontWeight] == 'Regular' ? Roboto[fontWeight] : Roboto[fontWeight]}`;
    }

    return (
        <Text onPress={onPress} style={textStyle} numberOfLines={numberOfLines}>
            {children ?? ''}
        </Text>
    );
}
