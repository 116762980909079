import React, { useState } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { useFont } from '@config';
import { Icon, Header } from '@components';
import styles from './styles';
import Modal from 'react-native-modal';
import { BaseColor } from '../../config';

export default function CostumModal({
    onFocus,
    onBlur,
    onValueChange,
    title,
    activator,
    renderRight,
    onPressRight,
    unscrollable,
    noHeader,
    children,
    renderComponent,
}) {
    const font = useFont();
    const cardColor = BaseColor.card;
    const [state, setState] = useState({ borderColor: cardColor });
    const [modalVisible, setModalVisible] = useState(false);
    /**
     * on Apply change sort
     */
    const onApply = (value) => {
        setModalVisible(false);
    };
    function onInputFocus() {
        setState({
            borderColor: BaseColor.primary,
        });
        onFocus && onFocus();
    }
    function onInputBlur() {
        setState({
            borderColor: cardColor,
        });
        onBlur && onBlur();
    }
    return (
        <View>
            <View style={[styles.contain, { backgroundColor: BaseColor.background }]}>
                <Modal
                    isVisible={modalVisible}
                    onSwipeComplete={() => {
                        setModalVisible(false);
                    }}
                    onBackdropPress={() => setModalVisible(false)}
                    onBackButtonPress={() => setModalVisible(false)}
                    backdropTransitionOutTiming={0}
                    hideModalContentWhileAnimating
                    animationIn="slideInRight"
                    animationOut="slideOutRight"
                    style={styles.bottomModal}
                >
                    <View style={{ backgroundColor: '#fff', flex: 1 }}>
                        {!noHeader && (
                            <Header
                                title={title}
                                renderLeft={() => {
                                    return <Icon name="arrow-left" size={20} color={BaseColor.text} enableRTL={true} />;
                                }}
                                onPressLeft={() => {
                                    setModalVisible(false);
                                }}
                                renderRight={renderRight}
                                onPressRight={
                                    !onPressRight
                                        ? () => {
                                              setModalVisible(false);
                                          }
                                        : null
                                }
                                shadow
                            />
                        )}
                        {unscrollable ? (
                            renderComponent ? (
                                renderComponent(onApply)
                            ) : (
                                children
                            )
                        ) : (
                            <ScrollView>{renderComponent ? renderComponent(onApply) : children}</ScrollView>
                        )}
                    </View>
                </Modal>
            </View>
            <TouchableOpacity onPress={() => setModalVisible(true)}>{activator}</TouchableOpacity>
        </View>
    );
}
