import { actionConstants } from '../constants';

let initialState = {
    all: [],
};
export function actions(state = initialState, action) {
    switch (action.type) {
        case 'INIT':
            return initialState;
        case actionConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case actionConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                all: action.actions,
            };
        case actionConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case actionConstants.DELETE_REQUEST:
            return {
                ...state,
                all: state.all?.map((act) => (act.id == action.id ? { ...act, deleting: true } : act)),
            };
        case actionConstants.DELETE_SUCCESS:
            return {
                ...state,
                all: state.all?.filter((act) => act.id != action.id),
            };
        case actionConstants.DELETE_FAILURE:
            return {
                ...state,
                all: state.all?.map((act) => (act.id == action.id ? { ...act, deleting: false, deletingError: action.error } : act)),
            };

        case actionConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true,
            };
        case actionConstants.CREATE_SUCCESS:
            return {
                ...state,
                all: [...(state.all ?? []), action.action],
                creating: false,
            };
        case actionConstants.CREATE_FAILURE:
            return {
                ...state,
                creating: false,
                createError: action.error,
            };

        case actionConstants.UPDATE_REQUEST:
            return {
                ...state,
                all: state.all?.map((act) => (act.id == action.id ? { ...act, updating: true } : act)),
            };
        case actionConstants.UPDATE_SUCCESS:
            return {
                ...state,
                all: state.all?.map((act) => (act.id == action.action.id ? { ...action.action, updating: false } : act)),
            };
        case actionConstants.UPDATE_FAILURE:
            return {
                ...state,
                all: state.all?.map((act) => {
                    if (act.id == action.action.id) {
                        const { updating, ...actionCopy } = act;
                        return { ...state, ...actionCopy, updatingError: action.error };
                    }
                }),
            };

        case actionConstants.GETBYID_REQUEST:
            return {
                ...state,
                current: { loading: true },
            };
        case actionConstants.GETBYID_SUCCESS:
            return {
                ...state,
                current: action.single,
            };
        case actionConstants.GETBYID_FAILURE:
            return {
                ...state,
                current: { ...state.singles, error: action.error, loading: false },
            };
        case actionConstants.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}
