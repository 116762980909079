import React, { useEffect, useRef, useState } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView, Linking } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { Button, DatePicker, Header, Image, TextInput } from '../../components';
import { BaseStyle, Images } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Menu from '../../components/Menu';
import { showMessage } from 'react-native-flash-message';
import { csvToObject } from '../../helpers/functions';
import { actionActions, clientActions, userActions } from '../../redux/actions';
import Form from './form';
import * as DocumentPicker from 'expo-document-picker';
import { Vibration } from 'react-native';
import LoadingFullscreen from '../../components/LoadingFullscreen';

function ActionNew({ navigation, createAction, getAllClient, creating, route }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    const [state, setState] = useState({
        sectionOrder: 0,
        sections: [],
        stores: [],
    });
    const [checkSumbition, setCheckSumbition] = useState(false);

    const downloadFromUrl = async () => {
        // const filename = 'Reporting-CSV-Vorlage.csv';
        // const result = await FileSystem.downloadAsync(
        //     'http://reporting-app-daten.gate-internet.de/uploads/Reporting-CSV-Vorlage.csv',
        //     FileSystem.documentDirectory + filename
        // );
        Linking.openURL('http://reporting-app-daten.gate-internet.de/uploads/Reporting-CSV-Vorlage.csv');
        // save(result.uri, filename, result.headers['content-Type']);
    };
    useEffect(() => {
        getAllClient();
    }, []);
    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };
    async function saveStores(add) {
        setState((s) => ({ ...s, storeLoading: true }));
        const file = await filepickup();
        if (file.type == 'cancel' || !file.type) {
            setState((s) => ({
                ...s,
                storeLoading: false,
            }));
            return;
        }
        if (file?.name.indexOf('csv') > -1) {
            const action = (results) => {
                if (results == 'error') {
                    showMessage({
                        message: 'Hochladen nicht möglich',
                        description: 'Fehler beim Lesen des Dokumment',
                        type: 'error',
                        icon: 'warning',
                    });
                    setState((s) => ({
                        ...s,
                        storeLoading: false,
                    }));
                    return;
                }

                const oldStores = state.stores;
                const oldStoreFilialnumbers = oldStores?.map((store) => store.filialnumber);
                let newStores = results.map((r) => (state.id ? { ...r, actionId: state.id } : r));

                if (add) {
                    newStores = newStores?.filter((store) => !(oldStoreFilialnumbers?.indexOf(store?.filialnumber) > -1));
                    newStores = [...(oldStores ?? []), ...(newStores ?? [])];
                }
                setState((s) => ({
                    ...s,
                    stores: newStores,
                    storeLoading: false,
                }));
                showMessage({
                    message: 'Hochladen erfolgreich',
                    description: (newStores?.length - (add ? oldStores?.length ?? 0 : 0) ?? 'Keine') + ' Märkte wurden hochgeladen',
                    type: 'success',
                    icon: 'success',
                });
            };
            csvToObject(file, action);
        } else {
            showMessage({
                message: 'Falsches Format',
                description: 'Bitte nur CSV Dateien (.csv)',
                type: 'warning',
                icon: 'warning',
            });
            setState((s) => ({
                ...s,
                storeError: 'Formatierungsfehler! Endung .csv ist erwartet',
                storeLoading: false,
            }));
        }
    }
    async function filepickup() {
        try {
            return await DocumentPicker.getDocumentAsync({
                //type: "text/*",
                type: 'text/*',
                copyToCacheDirectory: false,
                //copyToCacheDirectory: true,
                //multiple: true,
            });
        } catch (err) {
            showMessage({
                message: 'Hochladen nicht möglich',
                description: 'Fehler wärhend des Hochladens',
                type: 'warning',
                icon: 'warning',
            });
            setState((s) => ({
                ...s,
                storeError: 'Fehler beim Hochladen',
                storeLoading: false,
            }));
            return;
        }
    }
    function handleSubmit() {
        let globalInputs = [];
        for (let index = 0; index < state.sections.length; index++) {
            globalInputs = globalInputs.concat(state.sections[index].inputs?.map((input) => ({ ...input, section: (index + 1).toString() })));
        }
        if (!(state.name && state.startDate && state.endDate && globalInputs?.length > 0)) {
            setState((s) => ({
                ...s,
                nameError: state.name ? '' : 'Name erforderlich',
                startDateError: state.startDate ? '' : 'Erforderlich',
                endDateError: state.endDate ? '' : 'Erforderlich',
                formError: globalInputs?.length > 0 ? '' : 'Abfrage Erforderlich',
                //storesError: state.stores?.length > 0 ? '' : 'Bitte Märkte hochladen',
            }));
            Vibration.vibrate(50);
            return;
        }
        setCheckSumbition((s) => !s);
        let submitable = state.sections.filter((section) => section.inputs?.filter((input) => !input.submitable).length > 0).length == 0;
        if (submitable) {
            const action = {
                clientId: route.params?.client?.id,
                name: state.name,
                description: state.description,
                startDate: state.startDate,
                endDate: state.endDate,
                form: { inputs: globalInputs },
                stores: state.stores,
            };
            setState((s) => ({ ...s, creating: true }));
            createAction(
                { action: JSON.stringify(action) },
                (resp) => {
                    if (resp?.length > 0) {
                        showMessage({
                            message: resp?.length + ' Märkte nicht gespeichert',
                            description:
                                'Die Aktion wurde gespeichert, aber ' +
                                resp?.length +
                                ' Märkte könnte nicht gespeichert werden.\n Überprüfen Sie die Spalte "userId" im CSV datei und versuchen sie erneut\n\n Die ungültige userIds sind (' +
                                resp.sort((a, b) => a - b).filter((a, index) => (index == 0 ? true : a != resp[index - 1])) +
                                ')',
                            type: 'warning',
                            icon: 'warning',
                            autoHide: false,
                            color: 'red',
                        });
                    }
                    navigation.goBack();
                },
                (error) => {
                    setState((s) => ({ ...s, creating: false }));
                    showMessage({
                        message: 'Fehler beim Speichern',
                        type: 'warning',
                        icon: 'warning',
                        autoHide: false,
                    });
                }
            );
        }
        return;
    }
    const deleteSection = (order) => {
        setState((s) => ({ ...s, sections: s.sections.filter((sec) => sec.order != order) }));
    };
    const descriptionInput = useRef(null);

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title="Aktion hinzufügen"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1, backgroundColor: BaseColor.grayLight }}
                >
                    <ScrollView>
                        <View style={{ padding: 15, paddingBottom: 80 }}>
                            <View style={{ paddingBottom: 10 }}>
                                <Text headline bold>
                                    Basisdaten
                                </Text>
                            </View>
                            <View
                                style={{
                                    padding: 15,
                                    backgroundColor: BaseColor.whiteColor,
                                    borderBottomColor: BaseColor.grayTransparent,
                                    borderBottomWidth: 1,
                                }}
                            >
                                <View style={{ paddingBottom: 5 }}>
                                    <Text>Name der Aktion *</Text>
                                </View>
                                <TextInput
                                    onChangeText={(v) => setForm('name', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            nameError: '',
                                        });
                                    }}
                                    placeholder="Name *"
                                    success={!state.nameError}
                                    value={state.name}
                                    style={{ height: 40, borderRadius: 0 }}
                                    onSubmitEditing={() => descriptionInput.current.focus()}
                                    textError={state.nameError}
                                />
                                <View style={{ paddingTop: 10, paddingBottom: 5 }}>
                                    <Text>Beschreibung (Optional)</Text>
                                </View>
                                <TextInput
                                    inputRef={descriptionInput}
                                    onChangeText={(v) => setForm('description', v)}
                                    multiline
                                    numberOfLines={5}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            descriptionError: '',
                                        });
                                    }}
                                    placeholder="Beschreibung"
                                    success={!state.descriptionError}
                                    value={state.description}
                                    style={{ borderRadius: 0 }}
                                    textError={state.descriptionError}
                                />

                                <View style={{ paddingTop: 10, paddingBottom: 5, flexDirection: 'row' }}>
                                    <View style={{ flex: 1, paddingRight: 15 }}>
                                        <Text>Start *</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text>End *</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, paddingRight: 15 }}>
                                        <DatePicker
                                            mode="date"
                                            date={state.startDate}
                                            onChange={(v) => setForm('startDate', v)}
                                            placeholder={''}
                                            maximumDate={state.endDate}
                                            dateError={state.startDateError?.length > 0}
                                        />
                                        {state.startDateError && (
                                            <View>
                                                <Text footnote grayColor>
                                                    {state.startDateError}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <DatePicker
                                            mode="date"
                                            date={state.endDate}
                                            onChange={(v) => setForm('endDate', v)}
                                            minimumDate={state.startDate}
                                            placeholder={''}
                                            dateError={state.endDateError?.length > 0}
                                        />
                                        {state.endDateError && (
                                            <View>
                                                <Text footnote grayColor>
                                                    {state.endDateError}
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                </View>
                                <View style={{ paddingTop: 10, paddingBottom: 5, flexDirection: 'row' }}>
                                    <View style={{ flex: 1 }}>
                                        <Text>Märkte importieren</Text>
                                    </View>
                                    <View>
                                        <Menu
                                            activator={() => (
                                                <View>
                                                    <Icon name="questioncircleo" type="ad" size={20} />
                                                </View>
                                            )}
                                            component={() => (
                                                <View style={{ paddingBottom: 15 }}>
                                                    <Text headline semibold>
                                                        CSV Vorlage herunterladen
                                                    </Text>
                                                    <View style={{ paddingTop: 5 }}>
                                                        <View style={{ padding: 10, backgroundColor: BaseColor.grayLight, borderRadius: 20 }}>
                                                            <Text>
                                                                Hier können Sie die formatierte CSV-Vorlage für märkte herunterladen. Laden Sie die
                                                                Vorlage herunter und fügen sie die märkte ein.
                                                            </Text>
                                                            <Button
                                                                style={{ height: 35, width: 150, backgroundColor: BaseColor.whiteColor }}
                                                                textColor={BaseColor.text}
                                                                icon={<Icon name="download" type="ad" size={18} color={BaseColor.primary} />}
                                                                onPress={downloadFromUrl}
                                                            >
                                                                {' '}
                                                                Herunterladen
                                                            </Button>
                                                        </View>
                                                        <View
                                                            style={{
                                                                marginTop: 15,
                                                            }}
                                                        >
                                                            <Text>
                                                                Wenn Sie bereits über eine eigene CSV-Datei verfügen, können Sie wie folgt vorgehen,
                                                                um die Formatierung korrekt zu gestalten.
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    padding: 10,
                                                                    backgroundColor: BaseColor.grayLight,
                                                                    borderRadius: 20,
                                                                    marginVertical: 10,
                                                                }}
                                                            >
                                                                <Text>Alle Märkte in die CSV-Vorlage kopieren</Text>
                                                            </View>
                                                            <View style={{ alignItems: 'center' }}>
                                                                <Text>Oder</Text>
                                                            </View>
                                                            <View
                                                                style={{
                                                                    padding: 10,
                                                                    backgroundColor: BaseColor.grayLight,
                                                                    borderRadius: 20,
                                                                    marginTop: 15,
                                                                }}
                                                            >
                                                                <Text>
                                                                    Ihr eigenen CSV-Datei als{' '}
                                                                    <Text style={{ backgroundColor: BaseColor.primaryTransparent }}>
                                                                        {' '}
                                                                        "CSV UTF-8 (durch Trennzeichen getrennt)"{' '}
                                                                    </Text>{' '}
                                                                    oder{' '}
                                                                    <Text style={{ backgroundColor: BaseColor.primaryTransparent }}>
                                                                        {' '}
                                                                        "CSV UTF-8"{' '}
                                                                    </Text>{' '}
                                                                    speichern
                                                                </Text>
                                                                <View style={{ maxHeight: 100 }}>
                                                                    <Image resizeMode={'contain'} source={Images.saveTemplate} />
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            )}
                                        />
                                    </View>
                                </View>
                                <View style={{ width: '100%' }}>
                                    <View style={{ alignItems: 'flex-start', paddingBottom: 10 }}></View>
                                    <View style={{ alignItems: 'center', paddingBottom: 20 }}>
                                        {state.stores?.length > 0 ? (
                                            <Menu
                                                activatorStyle={{ width: '100%' }}
                                                activator={() => (
                                                    <View
                                                        style={{
                                                            paddingHorizontal: 15,
                                                            paddingVertical: 10,
                                                            height: 40,
                                                            width: '100%',
                                                            backgroundColor: BaseColor.greenTransparent,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <Icon name="file-csv" size={20} color={BaseColor.greenDark} />
                                                        <Text style={{ color: BaseColor.greenDark }}>
                                                            {' '}
                                                            {state.stores?.length == 0
                                                                ? 'Keine Markt hochgeladen'
                                                                : state.stores?.length > 0
                                                                ? `${state.stores?.length} Märkte hochgeladen`
                                                                : 'CSV hochladen'}
                                                        </Text>
                                                    </View>
                                                )}
                                                actionItems={[
                                                    {
                                                        text: 'Märkte hinzufügen',
                                                        leftIcon: () => (
                                                            <View
                                                                style={{
                                                                    backgroundColor: BaseColor.grayLight,
                                                                    height: 45,
                                                                    width: 45,
                                                                    borderRadius: 15,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Icon name="plus" type="ad" size={20} color={BaseColor.text} />
                                                            </View>
                                                        ),
                                                        action: () => saveStores(true),
                                                    },
                                                    {
                                                        text: 'Überschreiben',
                                                        leftIcon: () => (
                                                            <View
                                                                style={{
                                                                    backgroundColor: BaseColor.grayLight,
                                                                    height: 45,
                                                                    width: 45,
                                                                    borderRadius: 15,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Icon name="retweet" type="ad" size={20} color={BaseColor.text} />
                                                            </View>
                                                        ),
                                                        action: saveStores,
                                                    },
                                                    {
                                                        text: 'Löschen',
                                                        leftIcon: () => (
                                                            <View
                                                                style={{
                                                                    backgroundColor: BaseColor.grayLight,
                                                                    height: 45,
                                                                    width: 45,
                                                                    borderRadius: 15,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                                                            </View>
                                                        ),
                                                        action: () => setState((s) => ({ ...s, stores: [] })),
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <View style={{ width: '100%' }}>
                                                <Button
                                                    full
                                                    loading={state.storeLoading}
                                                    onPress={saveStores}
                                                    style={{
                                                        height: 40,
                                                        backgroundColor: BaseColor.grayColor,
                                                        borderRadius: 0,
                                                    }}
                                                    //textColor={'black'}
                                                    icon={<Icon name="file-csv" size={20} color={BaseColor.whiteColor} />}
                                                >
                                                    {'  '}
                                                    CSV hochladen
                                                </Button>
                                                {state.storesError && (
                                                    <Text footnote errorColor>
                                                        {state.storesError}
                                                    </Text>
                                                )}
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </View>

                            <View style={{ paddingBottom: 10, paddingTop: 20 }}>
                                <Text headline bold>
                                    Abfrage *
                                </Text>
                                {state.formError && (
                                    <Text footnote errorColor>
                                        {state.formError}
                                    </Text>
                                )}
                            </View>
                            {state.sections?.map((section) => (
                                <View key={'section' + section.order}>
                                    <Form
                                        section={section}
                                        setSection={(newSection) =>
                                            setState((s) => ({
                                                ...s,
                                                sections: s.sections.map((sec) => (sec.order == section.order ? { ...sec, ...newSection } : sec)),
                                            }))
                                        }
                                        checkSumbition={checkSumbition}
                                        deleteSection={() => deleteSection(section.order)}
                                    />
                                </View>
                            ))}
                            <View style={{ alignItems: 'center' }}>
                                <Button
                                    full
                                    onPress={() =>
                                        setState((s) => ({
                                            ...s,
                                            sectionOrder: s.sectionOrder + 1,
                                            sections: [...s.sections, { order: s.sectionOrder + 1, inputOrder: 0, inputs: [] }],
                                        }))
                                    }
                                    style={{ height: 40, backgroundColor: BaseColor.grayColor, borderRadius: 0 }}
                                    //textColor={'black'}
                                    icon={<Icon name="plus" size={20} type="ad" color={BaseColor.whiteColor} />}
                                >
                                    {' '}
                                    Sektion hinzufügen
                                </Button>
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                        <View style={{ alignItems: 'center', padding: 15, paddingTop: 0, flexDirection: 'row' }}>
                            <View style={{ paddingRight: 15, flex: 1 }}>
                                <Button onPress={() => navigation.goBack()} style={{ height: 40, backgroundColor: '#000' }}>
                                    {' '}
                                    Zurück
                                </Button>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Button onPress={handleSubmit} style={{ height: 40 }}>
                                    {' '}
                                    Speichern
                                </Button>
                            </View>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
            {state.creating && (
                <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <LoadingFullscreen />
                </View>
            )}
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { all } = state.clients;
    const { creating } = state.actions;
    return { user, creating, allClient: all };
}

const actionCreators = {
    createUser: userActions.register,
    createAction: actionActions.create,
    getAllClient: clientActions.getAll,
};

export default connect(mapState, actionCreators)(ActionNew);
