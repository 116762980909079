import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { BaseColor } from '@config';
import Text from '../Text';
import Icon from '../Icon';
export default function AlertComponent(props) {
    const { style, type, message, ...restProps } = props;

    return (
        <View
            {...restProps}
            style={[
                {
                    width: '100%',
                    backgroundColor:
                        type == 'success' ? BaseColor.greenTransparent : type == 'error' ? BaseColor.errorTransparent : BaseColor.blueTransparent,
                    borderRadius: 30,
                    padding: 15,
                    flexDirection: 'row',
                },
                style,
            ]}
        >
            <Icon
                name={type == 'success' ? 'check-circle' : type == 'error' ? 'exclamation-circle' : 'info-circle'}
                size={20}
                color={type == 'success' ? BaseColor.greenDark : type == 'error' ? BaseColor.errorColor : BaseColor.blueDark}
                enableRTL={true}
            />
            <Text
                style={{
                    color: type == 'success' ? BaseColor.greenDark : type == 'error' ? BaseColor.errorColor : BaseColor.blueDark,
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
            >
                {message}
            </Text>
        </View>
    );
}

AlertComponent.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    type: PropTypes.string,
    message: PropTypes.string,
};

AlertComponent.defaultProps = {
    style: {},
    type: 'success',
    message: '',
};
