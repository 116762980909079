import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Text } from '@components';
import { BaseColor } from '../../config';
import { Icon } from '..';

export default function PostOptions({ value, options, onChange }) {
    let handlerChange = (value) => {
        onChange(value);
    };

    return (
        <View>
            {options?.map((option, key) => (
                <TouchableOpacity
                    key={key}
                    style={{
                        flexDirection: 'row',
                        paddingVertical: 15,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                        borderBottomColor: BaseColor.grayLight,
                        alignItems: 'center',
                        paddingHorizontal: 20,
                        paddingBottom: 20,
                    }}
                    onPress={() => handlerChange(option)}
                >
                    <View style={{ borderRadius: 30, padding: 0, marginRight: 10 }}>
                        <Icon
                            name={option != value && option?.name != value?.name ? 'circle' : 'check-circle'}
                            solid={option == value || option?.name == value?.name}
                            color={'#000'}
                            size={20}
                        />
                    </View>
                    <Text>{option.name ?? option}</Text>
                </TouchableOpacity>
            ))}
        </View>
    );
}
