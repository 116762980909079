import React, { useEffect, useRef, useState } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { Button, Header, TextInput } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Menu from '../../components/Menu';
import { storeActions, userActions } from '../../redux/actions';
import { showMessage } from 'react-native-flash-message';

function StoreNew({ navigation, createStore, getAllUser, allUser, route }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });
    const [state, setState] = useState({ actionId: route.params.actionId });
    useEffect(() => {
        getAllUser();
    }, []);
    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };

    function handleSubmit() {
        if (
            !(
                (!state.creating && state.filialnumber && state.name && state.street && state.postalCode && state.country && state.city)
                //state.areaManager
                //state.userId > 0
            )
        ) {
            setState((s) => ({
                ...s,
                filialnumberError: state.filialnumber ? '' : 'Filialnummer erforderlich',
                nameError: state.name ? '' : 'Name erforderlich',
                streetError: state.street ? '' : 'Straße erforderlich',
                postalCodeError: state.postalCode ? '' : 'PLZ erforderlich',
                countryError: state.country ? '' : 'Land erforderlich',
                cityError: state.city ? '' : 'Stadt erforderlich',
                //areaManagerError: state.areaManager ? '' : 'areaManager erforderlich',
                //centerError: state.center ? '' : 'center erforderlich',
                //userError: state.userId > 0 ? '' : 'Bitte Mitarbeiter auswählen',
            }));
            return;
        }
        const { actionId, filialnumber, name, street, postalCode, country, city, planning, areaManager, center, userId, phoneNumber, email } = state;
        setForm('creating', true);
        createStore(
            { actionId, filialnumber, name, street, postalCode, country, city, planning, areaManager, center, userId, phoneNumber, email },
            () => {
                navigation.goBack();
            },
            () => {
                showMessage({
                    message: 'Fehler beim Speichern',
                    description: 'Bitte versuchen Sie später nochmal',
                    type: 'warning',
                    icon: 'warning',
                });
                setForm('creating', false);
            }
        );
    }

    const nameInput = useRef(null);
    const streetInput = useRef(null);
    const postalCodeInput = useRef(null);
    const cityInput = useRef(null);
    const countryInput = useRef(null);
    const planningInput = useRef(null);
    const areaManagerInput = useRef(null);
    const centerInput = useRef(null);
    const phoneNumberInput = useRef(null);
    const emailInput = useRef(null);

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title="Neuer Markt anlegen"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <ScrollView>
                        <View style={{ padding: 20, paddingBottom: 80 }}>
                            <TextInput
                                onChangeText={(v) => setForm('filialnumber', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        filialnumberError: '',
                                    });
                                }}
                                placeholder="Filialnummer *"
                                success={!state.filialnumberError}
                                value={state.filialnumber}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => nameInput.current.focus()}
                                textError={state.filialnumberError}
                            />
                            <TextInput
                                inputRef={nameInput}
                                onChangeText={(v) => setForm('name', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        nameError: '',
                                    });
                                }}
                                placeholder="Name *"
                                success={!state.nameError}
                                value={state.name}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => streetInput.current.focus()}
                                textError={state.nameError}
                            />
                            <TextInput
                                inputRef={streetInput}
                                onChangeText={(v) => setForm('street', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        streetError: '',
                                    });
                                }}
                                placeholder="Straße *"
                                success={!state.streetError}
                                value={state.street}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => postalCodeInput.current.focus()}
                                textError={state.streetError}
                            />

                            <TextInput
                                inputRef={postalCodeInput}
                                onChangeText={(v) => setForm('postalCode', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        postalCodeError: '',
                                    });
                                }}
                                placeholder="PLZ *"
                                success={!state.postalCodeError}
                                value={state.postalCode}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => cityInput.current.focus()}
                                textError={state.postalCodeError}
                            />

                            <TextInput
                                inputRef={cityInput}
                                onChangeText={(v) => setForm('city', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        cityError: '',
                                    });
                                }}
                                placeholder="Stadt *"
                                success={!state.cityError}
                                value={state.city}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => countryInput.current.focus()}
                                textError={state.cityError}
                            />
                            <TextInput
                                inputRef={countryInput}
                                onChangeText={(v) => setForm('country', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        countryError: '',
                                    });
                                }}
                                placeholder="Land *"
                                success={!state.countryError}
                                value={state.country}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => planningInput.current.focus()}
                                textError={state.countryError}
                            />
                            <TextInput
                                inputRef={planningInput}
                                onChangeText={(v) => setForm('planning', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        planningError: '',
                                    });
                                }}
                                placeholder="Planung"
                                success={!state.planningError}
                                value={state.planning}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => areaManagerInput.current.focus()}
                                textError={state.planningError}
                            />
                            <TextInput
                                inputRef={areaManagerInput}
                                onChangeText={(v) => setForm('areaManager', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        areaManagerError: '',
                                    });
                                }}
                                placeholder="Area manager"
                                success={!state.areaManagerError}
                                value={state.areaManager}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => centerInput.current.focus()}
                                textError={state.areaManagerError}
                            />
                            <TextInput
                                inputRef={centerInput}
                                onChangeText={(v) => setForm('center', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        centerError: '',
                                    });
                                }}
                                placeholder="Center"
                                success={!state.centerError}
                                value={state.center?.toString()}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                textError={state.centerError}
                                onSubmitEditing={() => phoneNumberInput.current.focus()}
                            />

                            <TextInput
                                inputRef={phoneNumberInput}
                                onChangeText={(v) => setForm('phoneNumber', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        phoneNumberError: '',
                                    });
                                }}
                                placeholder="Telefonnumber"
                                success={!state.phoneNumberError}
                                value={state.phoneNumber}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                textError={state.phoneNumberError}
                                onSubmitEditing={() => emailInput.current.focus()}
                            />

                            <TextInput
                                inputRef={emailInput}
                                onChangeText={(v) => setForm('email', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        emailError: '',
                                    });
                                }}
                                placeholder="Email"
                                success={!state.emailError}
                                value={state.email}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                textError={state.emailError}
                            />

                            <View style={{ marginTop: 15 }}>
                                <Menu
                                    activatorStyle={{ width: '100%' }}
                                    activator={() => (
                                        <View
                                            style={{
                                                paddingHorizontal: 10,
                                                paddingVertical: 10,
                                                height: 40,
                                                width: '100%',
                                                backgroundColor: BaseColor.card,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <View style={{ flex: 1, justifyContent: 'center', paddingHorizontal: 5 }}>
                                                {state.user ? (
                                                    <Text>{`${state.user?.id} - ${state.user?.firstname} ${state.user?.lastname}`}</Text>
                                                ) : (
                                                    <Text grayColor>Personal Auswählen</Text>
                                                )}
                                            </View>
                                            <View style={{}}>
                                                <Icon name="down" type="ad" color={BaseColor.grayColor} size={20} />
                                            </View>
                                        </View>
                                    )}
                                    actionItems={allUser?.rows
                                        ?.filter((user) => user?.roles[0]?.value == 3)
                                        ?.map((user) => ({
                                            text: `${user?.id} - ${user.firstname} ${user.lastname}`,
                                            textSecond: `Noch ${user?.stores?.filter((store) => !store.status).length} Märkte zu besuchen`,
                                            leftIcon: () => (
                                                <View
                                                    style={{
                                                        backgroundColor: BaseColor.grayLight,
                                                        height: 40,
                                                        width: 40,
                                                        borderRadius: 15,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Icon name="user" type="ad" size={18} color={BaseColor.text} />
                                                </View>
                                            ),
                                            action: () => {
                                                setForm('userId', user?.id), setForm('user', user);
                                            },
                                        }))}
                                />
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                        <View style={{ alignItems: 'center', paddingBottom: 20 }}>
                            <Button full loading={state.creating} onPress={handleSubmit} style={{ marginTop: 15, width: '50%', height: 40 }}>
                                Speichern
                            </Button>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user, all } = state.users;
    return { user, allUser: all };
}

const actionCreators = {
    createStore: storeActions.create,
    getAllUser: userActions.getAll,
};

export default connect(mapState, actionCreators)(StoreNew);
