import { offlineStorageConstants } from '../constants';
import { postConstants } from '../constants/post.constants';

export const offlineStorageActions = {
    changeLR,
    updateNewPostState,
    selectNewPostImage,
    unselectNewPostImage,
    unselectAllNewPostImage,
    addToNewPostImage,
    removeToNewPostImage,
    removeDuplicateToNewPostImage,
    updateNewPostImage,
    putToCover,
    saveImagesToAlbum,
    saveAlbums,
    updateExpoPushToken,
    simpleUpdate,
};

function simpleUpdate(value) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.SIMPLE_UPDATE, value });
    };
}

function changeLR(lr) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.CHANGE_LR, lr });
    };
}

function updateNewPostState(newPostState, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.UPDATE_NEW_POST_STATE, newPostState });
        callback && callback();
    };
}

function selectNewPostImage(selectedImage, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.SELECT_NEW_POST_IMAGE, selectedImage });
        callback && callback();
    };
}

function unselectNewPostImage(selectedImage, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.UNSELECT_NEW_POST_IMAGE, selectedImage });
        callback && callback();
    };
}
function unselectAllNewPostImage(callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.UNSELECT_ALL_NEW_POST_IMAGE });
        callback && callback();
    };
}

function addToNewPostImage(callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.ADD_TO_NEW_POST_IMAGE });
        callback && callback();
    };
}
function removeToNewPostImage(selectedImage, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.REMOVE_TO_NEW_POST_IMAGE, selectedImage });
        callback && callback();
    };
}
function removeDuplicateToNewPostImage(callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.REMOVE_DUPLICATE_TO_NEW_POST_IMAGE });
        callback && callback();
    };
}
function updateNewPostImage(selectedImage, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.UPDATE_NEW_POST_IMAGE, selectedImage });
        callback && callback();
    };
}
function putToCover(selectedImage, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.PUT_TO_COVER, selectedImage });
        callback && callback();
    };
}

function saveAlbums(albums, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.SAVE_ALBUMS, albums });
        callback && callback();
    };
}
function saveImagesToAlbum(albumId, albumImages, callback) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.SAVE_ALBUM_IMAGES, albumId, albumImages });
        callback && callback();
    };
}
function updateExpoPushToken(expoPushToken) {
    return (dispatch) => {
        dispatch({ type: offlineStorageConstants.UPDATE_EXPOPUSHTOKEN, expoPushToken });
    };
}
