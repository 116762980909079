import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet, ActivityIndicator, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import { BaseStyle } from '../../config';
import { BaseColor } from '../../config/theme';
import Text from '../Text';

export default function Button(props) {
    const { style, styleText, icon, outline, full, round, loading, children, type, textColor, small, onPress, unTouchable, ...rest } = props;
    const [isFocus, setIsFocus] = useState(false);
    const Comp = unTouchable ? View : TouchableOpacity;
    return (
        <Comp
            onPress={!loading ? onPress : () => {}}
            {...rest}
            style={StyleSheet.flatten([
                [
                    styles.default,
                    {
                        backgroundColor: type == 'error' ? BaseColor.errorColor : type == 'success' ? BaseColor.greenColor : BaseColor.primary,
                        transform: [{ scale: isFocus ? 0.99 : 1 }],
                    },
                ],
                outline && [
                    styles.outline,
                    {
                        backgroundColor: '#fff',
                        borderColor: type == 'error' ? BaseColor.errorColor : BaseColor.primary,
                    },
                ],
                full && styles.full,
                round && styles.round,
                style,
                !outline && !isFocus && BaseStyle.boxWithShadow,
            ])}
            onPressIn={() => setIsFocus(true)}
            onPressOut={() => setIsFocus(false)}
            activeOpacity={1}
        >
            {icon ? icon : null}
            {children && (
                <Text
                    style={StyleSheet.flatten([
                        styles.textDefault,
                        outline && { color: type == 'error' ? BaseColor.errorColor : type == 'success' ? BaseColor.greenColor : BaseColor.primary },
                        textColor && { color: textColor },
                        styleText,
                        small && { fontSize: 12 },
                    ])}
                    numberOfLines={1}
                >
                    {children}
                </Text>
            )}
            {loading ? (
                <ActivityIndicator
                    size={small ? 15 : 'small'}
                    color={outline ? BaseColor.primary : BaseColor.whiteColor}
                    style={{ paddingLeft: 5 }}
                />
            ) : null}
        </Comp>
    );
}

Button.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    icon: PropTypes.node,
    outline: PropTypes.bool,
    full: PropTypes.bool,
    round: PropTypes.bool,
    loading: PropTypes.bool,
};

Button.defaultProps = {
    style: {},
    icon: null,
    outline: false,
    full: false,
    round: false,
    loading: false,
};
