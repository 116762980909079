import { notificationConstants } from '../constants';

export function notifications(state = {}, action) {
    switch (action.type) {
        case 'INIT':
            return {};
        case notificationConstants.GET_ALL_REQUEST:
            return { ...state, allNotification: state.allNotification ? { ...state.allNotification, loading: true } : { loading: true } };
        case notificationConstants.GET_ALL_SUCCESS:
            return { ...state, allNotification: action.allNotification };
        case notificationConstants.GET_ALL_FAILURE:
            return {
                ...state,
                allNotification: state.allNotification ? { ...state.allNotification, loading: false, error: action.error } : { error: action.error },
            };

        case notificationConstants.GET_MESSAGE_REQUEST:
            return { ...state, messageNotification: state.messageNotification ? { ...state.messageNotification, loading: true } : { loading: true } };
        case notificationConstants.GET_MESSAGE_SUCCESS:
            return { ...state, messageNotification: action.messageNotification };
        case notificationConstants.GET_MESSAGE_FAILURE:
            return {
                ...state,
                messageNotification: state.messageNotification
                    ? { ...state.messageNotification, loading: false, error: action.error }
                    : { error: action.error },
            };

        case notificationConstants.GET_FAVORITE_REQUEST:
            return {
                ...state,
                favoriteNotification: state.favoriteNotification ? { ...state.favoriteNotification, loading: true } : { loading: true },
            };
        case notificationConstants.GET_FAVORITE_SUCCESS:
            return { ...state, favoriteNotification: action.favoriteNotification };
        case notificationConstants.GET_FAVORITE_FAILURE:
            return {
                ...state,
                favoriteNotification: state.favoriteNotification
                    ? { ...state.favoriteNotification, loading: false, error: action.error }
                    : { error: action.error },
            };

        case notificationConstants.GET_ALERT_REQUEST:
            return { ...state, alertNotification: state.alertNotification ? { ...state.alertNotification, loading: true } : { loading: true } };
        case notificationConstants.GET_ALERT_SUCCESS:
            return { ...state, alertNotification: action.alertNotification };
        case notificationConstants.GET_ALERT_FAILURE:
            return {
                ...state,
                alertNotification: state.alertNotification
                    ? { ...state.alertNotification, loading: false, error: action.error }
                    : { error: action.error },
            };
        /////////////////////////////NEW/////////////////////
        case notificationConstants.NEW_All:
            if (state.allNotification.content) {
                state.allNotification.content.unshift(action.allNotification);
                return {
                    ...state,
                    allNotification: { ...state.allNotification, totalElements: state.allNotification.totalElements + 1 },
                };
            } else
                return {
                    ...state,
                    //allNotification: { ...state.allNotification, content: [action.allNotification], totalElements: 1 },
                };
        case notificationConstants.NEW_MESSAGE:
            if (state.messageNotification && state.messageNotification.content) {
                state.messageNotification.content.unshift(action.messageNotification);
                return {
                    ...state,
                    messageNotification: { ...state.messageNotification, totalElements: state.messageNotification.totalElements + 1 },
                    //allNotification: { ...state.allNotification, totalElements: state.allNotification.totalElements + 1 },
                };
            } else
                return {
                    ...state,
                    messageNotification: { ...state.messageNotification, content: [action.messageNotification], totalElements: 1 },
                    //allNotification: { ...state.allNotification, totalElements: state.allNotification.totalElements + 1 },
                };
        case notificationConstants.NEW_FAVORITE:
            if (state.favoriteNotification && state.favoriteNotification.content) {
                state.favoriteNotification.content.unshift(action.favoriteNotification);
                return {
                    ...state,
                    favoriteNotification: { ...state.favoriteNotification, totalElements: state.favoriteNotification.totalElements + 1 },
                    //allNotification: { ...state.allNotification, totalElements: state.allNotification.totalElements + 1 },
                };
            } else
                return {
                    ...state,
                    favoriteNotification: { ...state.favoriteNotification, content: [action.favoriteNotification], totalElements: 1 },
                    //allNotification: { ...state.allNotification, totalElements: state.allNotification.totalElements + 1 },
                };
        case notificationConstants.NEW_ALERT:
            if (state.alertNotification && state.alertNotification.content) {
                state.alertNotification.content.unshift(action.alertNotification);
                return {
                    ...state,
                    alertNotification: { ...state.alertNotification, totalElements: state.alertNotification.totalElements + 1 },
                    //allNotification: { ...state.allNotification, totalElements: state.allNotification.totalElements + 1 },
                };
            } else
                return {
                    ...state,
                    alertNotification: { ...state.alertNotification, content: [action.alertNotification], totalElements: 1 },
                    //allNotification: { ...state.allNotification, totalElements: state.allNotification.totalElements + 1 },
                };

        //DELETE//
        case notificationConstants.DELETE_MESSAGE_REQUEST:
            return {
                ...state,
                messageNotification: state.messageNotification ? { ...state.messageNotification, deleting: true } : { deleting: true },
            };
        case notificationConstants.DELETE_MESSAGE_SUCCESS:
            if (state.messageNotification) {
                state = {
                    ...state,
                    messageNotification: action.messageNotification,
                };
            }
            return state;
        case notificationConstants.DELETE_MESSAGE_FAILURE:
            return {
                ...state,
                messageNotification: state.messageNotification
                    ? { ...state.messageNotification, deleting: false, deletingError: action.error }
                    : { deletingError: action.error },
            };

        case notificationConstants.DELETE_FAVORITE_REQUEST:
            return { ...state, favoriteNotification: { ...state.favoriteNotification, deleting: true } };
        case notificationConstants.DELETE_FAVORITE_SUCCESS:
            if (state.favoriteNotification) {
                state = {
                    ...state,
                    allNotification: {
                        ...state.allNotification,
                        totalElements: state.allNotification.totalElements - state.favoriteNotification.totalElements,
                    },
                };
            }
            const { favoriteNotification, ...stateCopy1 } = state;
            return stateCopy1;
        case notificationConstants.DELETE_FAVORITE_FAILURE:
            return {
                ...state,
                favoriteNotification: state.favoriteNotification
                    ? { ...state.favoriteNotification, deleting: false, error: action.error }
                    : { error: action.error },
            };

        case notificationConstants.DELETE_ALERT_REQUEST:
            return { ...state, alertNotification: { ...state.alertNotification, deleting: true } };
        case notificationConstants.DELETE_ALERT_SUCCESS:
            if (state.alertNotification) {
                state = {
                    ...state,
                    allNotification: {
                        ...state.allNotification,
                        totalElements: state.allNotification.totalElements - state.alertNotification.totalElements,
                    },
                };
            }
            const { alertNotification, ...stateCopy2 } = state;
            return stateCopy2;
        case notificationConstants.DELETE_ALERT_FAILURE:
            return {
                ...state,
                alertNotification: state.alertNotification
                    ? { ...state.alertNotification, deleting: false, error: action.error }
                    : { error: action.error },
            };
        case notificationConstants.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}
