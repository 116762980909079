import { store } from '@store';

export function authHeader() {
    try {
        let accessToken = store.getState().users?.user?.token;
        if (accessToken) {
            return { Authorization: `Bearer ${accessToken}` };
        } else {
            return {};
        }
    } catch (error) {
        return {};
    }
}
