import { actionConstants } from '../constants';
import { actionService } from '../services';
import { showMessage } from 'react-native-flash-message';

export const actionActions = {
    create,
    update,
    getAll,
    delete: _delete,
    getSingle,
    getAllWR,
    getSingleWR,
    assignUser,
};

function create(action, callback, errorCallback) {
    return (dispatch) => {
        dispatch(request({ action }));
        actionService.create(action).then(
            (action) => {
                if (action != null) {
                    dispatch(success(action));
                    callback && callback(action);
                } else {
                    dispatch(failure(''));
                    errorCallback && errorCallback();
                }
            },
            (error) => {
                dispatch(failure(error.toString()));
                errorCallback && errorCallback(error);
            }
        );
    };

    function request(action) {
        return { type: actionConstants.CREATE_REQUEST, action };
    }
    function success(action) {
        return { type: actionConstants.CREATE_SUCCESS, action };
    }
    function failure(error) {
        return { type: actionConstants.CREATE_FAILURE, error };
    }
}

function update(action, callback, errorCallback) {
    return (dispatch) => {
        action.action && dispatch(request(JSON.parse(action.action).id));
        actionService.update(action).then(
            (action) => {
                dispatch(success(action));
                callback && callback(action);
            },
            (error) => {
                dispatch(failure(error.toString()));
                errorCallback && errorCallback(error);
            }
        );
    };

    function request(id) {
        return { type: actionConstants.UPDATE_REQUEST, id };
    }
    function success(action) {
        return { type: actionConstants.UPDATE_SUCCESS, action };
    }
    function failure(error) {
        return { type: actionConstants.UPDATE_FAILURE, error };
    }
}
function getAllWR(filter, request, success, failure) {
    request();
    actionService.getAll(filter).then(
        (actions) => success(actions),
        (error) => failure(error?.toString())
    );
}

function getSingleWR(id, callbackState) {
    actionService.getById(id).then(
        (action) => callbackState((s) => ({ ...s, action, loading: false })),
        (error) => callbackState((s) => ({ ...s, error, loading: false }))
    );
}

function getAll(filter, callback, errorCallback) {
    return (dispatch) => {
        dispatch(request());
        actionService.getAll(filter).then(
            (actions) => {
                dispatch(success(actions));
                callback && callback(actions);
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: actionConstants.GETALL_REQUEST };
    }
    function success(actions) {
        return { type: actionConstants.GETALL_SUCCESS, actions };
    }
    function failure(error) {
        return { type: actionConstants.GETALL_FAILURE, error };
    }
}

function _delete(id, callback) {
    return (dispatch) => {
        dispatch(request(id));
        actionService.delete(id).then(
            (resp) => {
                showMessage({
                    message: 'Aktion wurde gelöscht',
                    icon: 'success',
                });
                dispatch(success(id));
                callback && callback();
            },
            (error) => {
                showMessage({
                    message: 'Fehler beim Löschen',
                    icon: 'warning',
                });
                dispatch(failure(id, error.toString()));
            }
        );
    };

    function request(id) {
        return { type: actionConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: actionConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: actionConstants.DELETE_FAILURE, id, error };
    }
}

function getSingle(id, callback) {
    return (dispatch) => {
        dispatch(request(id));
        actionService.getById(id).then(
            (action) => {
                dispatch(success(id, action));
                callback && callback(action);
            },
            (error) => dispatch(failure(id, error.toString()))
        );
    };

    function request(id) {
        return { type: actionConstants.GETBYID_REQUEST, id };
    }
    function success(id, action) {
        return { type: actionConstants.GETBYID_SUCCESS, id, action };
    }
    function failure(id, error) {
        return { type: actionConstants.GETBYID_FAILURE, id, error };
    }
}

function assignUser(id, userId, request, success, failure) {
    return () => {
        request();
        actionService.assignUser(id, userId).then(
            () => {
                success();
            },
            (error) => failure(error)
        );
    };
}
