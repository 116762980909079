import React, { useCallback } from 'react';
import { ScrollView, View } from 'react-native';
import { useTheme } from '@config';
import { Header, SafeAreaView, Icon } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '@actions';
import { useFocusEffect } from '@react-navigation/native';
import SettingsTag from '../SettingTag';
import { userSelector } from '../../selectors';
import * as SplashScreen from 'expo-splash-screen';
import { TouchableOpacity } from 'react-native';
import { Text } from '../../components';
import { BaseColor, BaseStyle } from '../../config';
import { listFiles } from '../../helpers/apis';
import { useEffect } from 'react';
import { useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { Linking } from 'react-native';

export default function CSVFiles({ navigation }) {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const [list, setList] = useState(null);
    const user = useSelector(userSelector.user);
    const roles = user?.roles;

    useFocusEffect(
        useCallback(() => {
            dispatch(userActions.getUserAuth());
            return () => {};
        }, [])
    );
    async function loadList() {
        const result = await (await listFiles('csv')).json();
        if (result?.length > 0) setList(result);
    }
    useEffect(() => {
        loadList();
    }, []);
    if (!user) {
        return <View></View>;
    }
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);
    const downloadFromUrl = async (name) => {
        // const filename = 'Reporting-CSV-Vorlage.csv';
        // const result = await FileSystem.downloadAsync(
        //     'http://reporting-app-daten.gate-internet.de/uploads/Reporting-CSV-Vorlage.csv',
        //     FileSystem.documentDirectory + filename
        // );
        Linking.openURL(`http://reporting-app-daten.gate-internet.de/uploads/csv/${name}.csv`);
        // save(result.uri, filename, result.headers['content-Type']);
    };
    return (
        <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
            <Header
                title="CSV Dateien"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" color={colors.text} enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <ScrollView>
                    <View style={{ padding: 20 }}>
                        {!list ? (
                            <View>
                                <ActivityIndicator color={BaseColor.primary} size={'large'} />
                            </View>
                        ) : (
                            list?.map((name) => (
                                <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
                                    <View style={{ flex: 1, justifyContent: 'center' }}>
                                        <Text semibold>{name}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', borderRadius: 20, overflow: 'hidden', ...BaseStyle.boxWithShadow1 }}>
                                        <View style={{ borderRightWidth: 1, borderRightColor: BaseColor.grayLight }}>
                                            <TouchableOpacity
                                                style={{
                                                    padding: 10,
                                                    paddingHorizontal: 15,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: BaseColor.whiteColor,
                                                }}
                                                onPress={() => downloadFromUrl(name)}
                                            >
                                                <Icon name="download" type="ad" size={20} />
                                            </TouchableOpacity>
                                        </View>
                                        <View>
                                            <TouchableOpacity
                                                style={{
                                                    padding: 10,
                                                    paddingHorizontal: 15,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: BaseColor.whiteColor,
                                                }}
                                            >
                                                <Icon name="delete" type="ad"  size={20} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            ))
                        )}
                    </View>
                </ScrollView>
            </SafeAreaView>
        </View>
    );
}

const menuIcon = {
    width: 60,
    height: 60,
    borderRadius: 50,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 20,
    alignItems: 'center',
    justifyContent: 'center',
};
