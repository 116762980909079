/**
 * Define Const color use for whole application
 */
export const BaseColor = {
    firstColor: '#2d3a50',
    gray: '#7e9294',
    background: '#000000',
    grayLight: '#f5f7f7',
    grayColor: '#7e9294',
    grayDark: '#2d3a50',
    grayTransparent: '#7e929433',
    dividerColor: '#eee',
    whiteColor: '#FFFFFF',
    fieldColor: '#F5F5F5',
    yellowColor: '#FDC60A',
    navyBlue: '#3C5A99',
    kashmir: '#5D6D7E',
    orangeColor: '#E5634D',
    blueColor: '#2d8fff', //5DADE2
    blueTransparent: '#2d8fff1a',
    blueDark: '#0b6ddd',
    pinkColor: '#A569BD',
    greenColor: '#58D68D',
    greenTransparent: '#58D68D1a',
    greenDark: '#25b139',
    errorColor: '#ff5555',
    errorTransparent: '#ff55551a',
    errorDark: '#bb0000',
    primary: '#ff7b00',
    primaryDark: '#52b600',
    primaryLight: '#edeef4',
    primaryTransparent: '#ff7b0010', //'#bdca0210',
    urgent: '#06C5DF',
    top: '#FF9900',
    yellow: '#f9c00a ',//#F6C901
    text: '#000000',
    accent: '#607D8B',
    background: 'white',
    card: '#F5F5F5',
    border: '#c7c7cc',
};

export const useFont = () => {
    return 'Roboto';
};
export const useTheme = () => {
    return { colors: BaseColor };
};
export const fonts = () => {
    return {
        black: 'Roboto-Black',
        blackItalic: 'Roboto-BlackItalic',
        bold: 'Roboto-Bold',
        boldItalic: 'Roboto-BoldItalic',
        italic: 'Roboto-Italic',
        light: 'Roboto-Light',
        lightItalic: 'Roboto-LightItalic',
        medium: 'Roboto-Medium',
        mediumItalic: 'Roboto-MediumItalic',
        regular: 'Roboto-Regular',
    };
};
