import { SaveFormat, manipulateAsync } from 'expo-image-manipulator';
import * as FileSystem from 'expo-file-system';
import FileResizer from 'react-image-file-resizer';
import moment from 'moment/moment';

export const priceFormat = (price) => {
    if (!price) return '0';
    price = price.toString();
    let formatedPrice = '';
    let index = 0;
    if (price.length % 3 == 1) {
        formatedPrice += price[0] + ' ';
        index = 1;
    } else if (price.length % 3 == 2) {
        formatedPrice += price[0] + price[1] + ' ';
        index = 2;
    }
    for (let id = index; id < price.length; id += 3) {
        id == price.length - 3
            ? (formatedPrice += `${price[id]}${price[id + 1]}${price[id + 2]}`)
            : (formatedPrice += `${price[id]}${price[id + 1]}${price[id + 2]} `);
    }
    return formatedPrice.trim();
};

export function formatDate(date, language) {
    moment.locale('de');
    const now = moment();
    var duration = moment.duration(now.diff(date));
    var daysSpace = duration.days();
    var hoursSpace = duration.hours();

    var result;
    if (daysSpace > 6) {
        result = moment(date).format('ddd Do MMMM YYYY');
    } else if (daysSpace > 3) {
        result = moment(date).format('llll');
    } else if (hoursSpace > 5) {
        result = moment(date).calendar();
    } else {
        result = moment(date).startOf('second').fromNow();
    }
    return result;
}
export function formatDate2(date) {
    moment.locale('de');
    return moment(date).format('ll');
}
export function formatDate3(date) {
    moment.locale('de');
    return moment(date).format('llll');
}
export function compareDate(d1, d2) {
    const start = d2 ? d1 : moment();
    const end = d2 ? d2 : d1;
    var duration = moment.duration(moment(start).diff(moment(end)));
    return duration;
}

export function dateDifference(date) {
    return moment().diff(moment(date), 'millisecond');
}

export function dateWatch(d1, d2, invert) {
    let end = d2 ? d1 : moment();
    let start = d2 ? d2 : d1;
    if (invert) {
        const endClone = end;
        end = start;
        start = endClone;
    }
    const yDiff = moment(start).diff(moment(end), 'year');
    const dDiff = moment(start).diff(moment(end), 'day') % 365;
    const hDiff = moment(start).diff(moment(end), 'hour') % 24;
    const minDiff = moment(start).diff(moment(end), 'minute') % 60;
    const sDiff = moment(start).diff(moment(end), 'second') % 60;

    let result = '';

    if (yDiff > 0) {
        result += yDiff + ':';
    }

    if (dDiff > 0) {
        result += (dDiff.toString().length == 1 ? '0' : '') + dDiff + ':';
    }

    if (hDiff > 0) {
        result += (hDiff.toString().length == 1 ? '0' : '') + hDiff + ':';
    }

    if (minDiff >= 0) {
        result += (minDiff.toString().length == 1 ? '0' : '') + minDiff + ':';
    }

    if (sDiff >= 0) {
        result += (sDiff.toString().length == 1 ? '0' : '') + sDiff;
    }
    return result;
}

export function printWatch(date) {
    moment.locale('de');
    return moment(date).format('HH:mm');
}
export function scrollToId(id) {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}
export const getImageUrl = (path) => `http://reporting-app-daten.gate-internet.de/uploads/${path}`;

export function sendNot() {}

export function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'Md' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

export function noAccent(input) {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
export async function resizeFile(file) {
    return await new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            1000,
            700,
            'JPEG',
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            'blob'
            //'base64'
        );
    });
}
export async function compressImage(uri) {
    let resp = await manipulateAsync(
        uri,
        [
            {
                resize: {
                    height: 700,
                },
            },
        ],
        { format: SaveFormat.JPEG, compress: 1 }
    );
    return resp.uri;
}

export async function csvToObject(csvFile, action) {
    const file = csvFile.file;

    if (file) {
        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = function (event) {
            const csvData = event.target.result;
            const dataArray = CSVToArray(csvData); // Convert CSV to array of arrays
            action(dataArray.filter((d) => d.filialnumber?.length));
        };
    }

    // Function to convert CSV string to array of arrays
    function CSVToArray(csvString) {
        var lines = csvString.split('\n');
        let attributes = lines[0].trim().split(';');
        const data = lines.slice(1)?.map((line) => {
            let res;
            const lineAsArray = line.trim().split(';');
            res = {};
            attributes.forEach((a, i) => {
                res = { ...res, [a]: lineAsArray[i] };
            });
            return res;
        });
        return data;
    }
}

export async function downloadFileInlocal(uri) {
    return await FileSystem.downloadAsync(uri, FileSystem.documentDirectory + 'small.mp4');
}
