import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Icon } from '@components';

import Profile from '@screens/Profile';

import { BaseColor, BaseStyle } from '../config';
import { View } from 'react-native-animatable';
import { AppState, Platform, StatusBar } from 'react-native';
import * as NavigationBar from 'expo-navigation-bar';
import { useEffect } from 'react';
import NewAction from '../screens/HomeEvent/NewAction';
import Clients from '../screens/HomeEvent/Clients';
import NewClient from '../screens/HomeEvent/NewClient';
import EditClient from '../screens/HomeEvent/EditClient';
import UserList from '../screens/UserList';
import UserEdit from '../screens/UserEdit';
import UserNew from '../screens/UserNew';
import UserDetails from '../screens/UserDetails';
import ActionList from '../screens/ActionList';
import ActionNew from '../screens/ActionNew';
import ActionEdit from '../screens/ActionEdit';
import StoreList from '../screens/StoreList';
import StoreEdit from '../screens/StoreEdit';
import StoreDetails from '../screens/StoreDetails';
import { useSelector } from 'react-redux';
import { offlineStorageSelector, userSelector } from '../selectors';
import { DeviceType } from 'expo-device';
import PreviewImage from '../screens/PreviewImage';
import Statistics from '../screens/Statistics';
import StoreNew from '../screens/StoreNew';
import CSVFiles from '../screens/CSVFiles';
import AssignClient from '../screens/AssignClient';
import ProfileEdit from '../screens/ProfileEdit';

const MainStack = createStackNavigator();
const BottomTab = createBottomTabNavigator();

export default function Main() {
    useEffect(() => {
        if (Platform.OS === 'android') {
            StatusBar.setBarStyle('dark-content', true);
            StatusBar.setBackgroundColor(BaseColor.whiteColor, true);
            NavigationBar.setBackgroundColorAsync('white');
            NavigationBar.setButtonStyleAsync('dark');
        }

        AppState.addEventListener('change', (state) => {
            if (Platform.OS === 'android') {
                setTimeout(() => {
                    StatusBar.setBackgroundColor(BaseColor.whiteColor, true);
                    NavigationBar.setBackgroundColorAsync('white');
                    NavigationBar.setButtonStyleAsync('dark');
                }, 100);
            }
        });

        return () => {};
    }, []);
    return (
        <MainStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName="BottomTabNavigator"
        >
            <MainStack.Screen name="BottomTabNavigator" component={BottomTabNavigator} />
            <MainStack.Screen name="ActionList" component={ActionList} />
            <MainStack.Screen name="ActionNew" component={ActionNew} />
            <MainStack.Screen name="ActionEdit" component={ActionEdit} />
            {/* <MainStack.Screen name="ActionDetails" component={ActionDetails} /> */}
            <MainStack.Screen name="Clients" component={Clients} />
            <MainStack.Screen name="NewClient" component={NewClient} />
            <MainStack.Screen name="EditClient" component={EditClient} />
            <MainStack.Screen name="UserNew" component={UserNew} />
            <MainStack.Screen name="UserList" component={UserList} />
            <MainStack.Screen name="UserEdit" component={UserEdit} />
            <MainStack.Screen name="UserDetails" component={UserDetails} />
            <MainStack.Screen name="StoreList" component={StoreList} getId={({ params }) => params.action?.id} />
            <MainStack.Screen name="StoreEdit" component={StoreEdit} />
            <MainStack.Screen name="StoreNew" component={StoreNew} />
            <MainStack.Screen name="StoreDetails" component={StoreDetails} />
            <MainStack.Screen name="PreviewImage" component={PreviewImage} />
            <MainStack.Screen name="Statistics" component={Statistics} />
            <MainStack.Screen name="CSVFiles" component={CSVFiles} />
            <MainStack.Screen name="AssignClient" component={AssignClient} />
            <MainStack.Screen name="ProfileEdit" component={ProfileEdit} />
        </MainStack.Navigator>
    );
}

function BottomTabNavigator() {
    const device = useSelector(offlineStorageSelector.device);
    const user = useSelector(userSelector.user);
    return (
        <BottomTab.Navigator
            initialRouteName="Home"
            screenOptions={{
                tabBarStyle: { backgroundColor: '#ffffff', height: 55, paddingTop: 8, paddingBottom: 5 },
                tabBarInactiveTintColor: '#000000',
                tabBarActiveTintColor: BaseColor.primary,
                headerShown: false,
                tabBarLabelStyle: {
                    fontSize: 12,
                    // marginTop: -8,
                    // marginBottom: 5,
                    //fontWeight: '300',
                },
                //tabBarShowLabel: false,
            }}
        >
            {!(user?.roles[0] == 2 && user?.client == null) && (
                <BottomTab.Screen
                    name="Home"
                    component={user?.roles[0] == 2 ? ActionList : Clients}
                    initialParams={user?.roles[0] == 2 ? { client: user?.client } : {}}
                    options={{
                        title: 'Start',
                        tabBarIcon: ({ color }) => {
                            return <Icon color={color} name={color == BaseColor.primary ? 'home' : 'home'} type={'ad'} size={23} />;
                        },
                    }}
                />
            )}
            {/* <BottomTab.Screen
                name="Purchases"
                component={NewAction}
                options={{
                    title: 'Märkte',
                    tabBarIcon: ({ color }) => {
                        return (
                            <Icon color={color} name={color == BaseColor.primary ? 'shoppingcart' : 'shoppingcart'} type={'ad'} size={23} outline />
                        );
                    },
                }}
            /> */}
            {!(user?.roles[0] == 2 && user?.client == null) && (
                <BottomTab.Screen
                    name="Synchronise"
                    component={Statistics}
                    options={{
                        title: '',
                        tabBarIcon: ({ color }) => {
                            return (
                                <View
                                    style={{
                                        height: 40,
                                        width: 40,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'black',
                                        borderRadius: 20,
                                        marginTop: device == 0,
                                        ...BaseStyle.boxWithShadow,
                                    }}
                                >
                                    <Icon
                                        color={BaseColor.primary}
                                        name={color == BaseColor.primary ? 'piechart' : 'piechart'}
                                        type={'ad'}
                                        size={25}
                                        solid
                                    />
                                </View>
                            );
                        },
                    }}
                />
            )}
            {/* {user?.roles[0] == 2 && (
                <BottomTab.Screen
                    name="Personal"
                    component={UserList}
                    options={{
                        title: 'Personal',
                        tabBarIcon: ({ color }) => {
                            return <Icon color={color} name={color == BaseColor.primary ? 'team' : 'team'} type={'ad'} size={23} solid />;
                        },
                    }}
                />
            )} */}

            <BottomTab.Screen
                name="Profile"
                component={Profile}
                options={{
                    title: 'Konto',
                    tabBarIcon: ({ color }) => {
                        return <Icon solid color={color} name={color == BaseColor.primary ? 'user' : 'user'} type={'ad'} size={23} />;
                    },
                }}
            />
        </BottomTab.Navigator>
    );
}
