import React, { useEffect, useRef, useState } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { AlertComponent, Button, Header, Image, TextInput } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';
import Menu from '../../components/Menu';
import * as ImagePicker from 'expo-image-picker';
import { saveImg } from '../../helpers/apis';
import { showMessage } from 'react-native-flash-message';
import { compressImage } from '../../helpers/functions';
import CostumModal from '../../components/CostumModal';
import PostOptions from '../../components/PostOptions';
import { clientActions, userActions } from '../../redux/actions';

const roles = [
    {
        name: 'Projektleiter',
        value: 1,
    },
    {
        name: 'Kunde',
        value: 2,
    },
    {
        name: 'Außendienstmitarbeiter',
        value: 3,
    },
];
function UserEdit({ navigation, updateUser, getAllClient, allClient, route }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    const [state, setState] = useState(route.params.user);
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    useEffect(() => {
        getAllClient();
    }, []);
    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };

    function handleSubmit() {
        if (!(!state.creating && state.firstname && state.lastname && state.roles.length > 0 && (state.roles[0]?.value != 2 || state.client))) {
            setState((s) => ({
                ...s,
                firstnameError: state.firstname ? '' : 'Vorname erforderlich',
                lastnameError: state.lastname ? '' : 'Nachname erforderlich',
                rolesError: state.roles.length > 0 ? '' : 'Bitte Rolle auswählen',
                clientError: state.roles[0]?.value != 2 || state.client ? '' : 'Bitte Kunde auswählen',
            }));
            return;
        }
        const { id, firstname, lastname, password, phonenumber, email } = state;
        setForm('creating', true);
        updateUser(
            { id, firstname, lastname, password, phonenumber, email, roles: state.roles, clientId: state.client?.id },
            () => {
                setForm('creating', false);
                navigation.goBack();
            },
            () => setForm('creating', false)
        );
    }

    const lastnameInput = useRef(null);
    const passwordInput = useRef(null);
    const phonenumberInput = useRef(null);
    const emailInput = useRef(null);

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title="Benutzer bearbeiten"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <ScrollView>
                        <View style={{ padding: 20, paddingBottom: 80 }}>
                            <View style={{ paddingVertical: 10, alignItems:'center' }}>
                                <Text bold headline>ID - {state.id}</Text>
                            </View>
                            <TextInput
                                onChangeText={(v) => setForm('firstname', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        firstnameError: '',
                                    });
                                }}
                                placeholder="Vorname *"
                                success={!state.firstnameError}
                                value={state.firstname}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => lastnameInput.current.focus()}
                                textError={state.firstnameError}
                            />
                            <TextInput
                                inputRef={lastnameInput}
                                onChangeText={(v) => setForm('lastname', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        lastnameError: '',
                                    });
                                }}
                                placeholder="Nachname *"
                                success={!state.lastnameError}
                                value={state.lastname}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => passwordInput.current.focus()}
                                textError={state.lastnameError}
                            />

                            <TextInput
                                autoComplete="password"
                                inputRef={passwordInput}
                                onChangeText={(v) => setForm('password', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        passwordError: '',
                                    });
                                }}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                placeholder="Passwort *"
                                secureTextEntry={!passwordVisibility}
                                name="password"
                                textContentType="password"
                                success={!state.passwordError}
                                value={state.password}
                                icon={
                                    <TouchableOpacity
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingHorizontal: 5,
                                            height: 50,
                                        }}
                                        onPress={() => setPasswordVisibility((pw) => !pw)}
                                    >
                                        <Icon name={passwordVisibility ? 'eye' : 'eye-slash'} size={18} enableRTL={true} />
                                    </TouchableOpacity>
                                }
                                onSubmitEditing={() => phonenumberInput.current.focus()}
                            />
                            <TextInput
                                inputRef={phonenumberInput}
                                onChangeText={(v) => setForm('phonenumber', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        phonenumberError: '',
                                    });
                                }}
                                placeholder="Telefonnummer"
                                value={state.phonenumber}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => emailInput.current.focus()}
                            />

                            <TextInput
                                autoComplete="email"
                                inputRef={emailInput}
                                onChangeText={(v) => setForm('email', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        emailError: '',
                                    });
                                }}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                placeholder="E-Mail"
                                name="email"
                                textContentType="emailAddress"
                                success={!state.emailError}
                                value={state.email}
                                onSubmitEditing={handleSubmit}
                            />

                            <View style={{ marginTop: 15 }}>
                                <CostumModal
                                    title="Rolle auswählen"
                                    activator={
                                        <View
                                            style={[
                                                BaseStyle.textInput,
                                                {
                                                    backgroundColor: BaseColor.card,
                                                    height: 40,
                                                    borderRadius: 0,
                                                },
                                            ]}
                                        >
                                            <View>
                                                {state.roles.length > 0 ? (
                                                    <Text>{state.roles[0]?.name}</Text>
                                                ) : (
                                                    <Text style={{ color: state.rolesError ? 'red' : BaseColor.grayColor }}>Rolle auswählen *</Text>
                                                )}
                                            </View>
                                            <Icon name="chevron-right" size={15} color={BaseColor.gray} style={{ marginRight: 5 }} enableRTL={true} />
                                        </View>
                                    }
                                    renderComponent={(onApply) => (
                                        <PostOptions
                                            value={state.roles[0]}
                                            options={roles}
                                            onChange={(v) => {
                                                setForm('roles', [v]);
                                                if (v.value != 2) setForm('client', null);
                                                onApply();
                                            }}
                                        />
                                    )}
                                />
                            </View>
                            {state.roles[0]?.value == 2 && (
                                <View style={{ marginTop: 15 }}>
                                    <CostumModal
                                        title="Kunde auswählen"
                                        activator={
                                            <View
                                                style={[
                                                    BaseStyle.textInput,
                                                    {
                                                        backgroundColor: BaseColor.card,
                                                        height: 40,
                                                        borderRadius: 0,
                                                    },
                                                ]}
                                            >
                                                <View>
                                                    {state.client ? (
                                                        <Text>{state.client.name}</Text>
                                                    ) : (
                                                        <Text style={{ color: state.clientError ? 'red' : BaseColor.grayColor }}>
                                                            Firma Auswählen *
                                                        </Text>
                                                    )}
                                                </View>
                                                <Icon
                                                    name="chevron-right"
                                                    size={15}
                                                    color={BaseColor.gray}
                                                    style={{ marginRight: 5 }}
                                                    enableRTL={true}
                                                />
                                            </View>
                                        }
                                        renderComponent={(onApply) => (
                                            <PostOptions
                                                value={state.client}
                                                options={allClient.rows}
                                                onChange={(v) => {
                                                    setForm('client', v);
                                                    onApply();
                                                }}
                                            />
                                        )}
                                    />
                                </View>
                            )}
                        </View>
                    </ScrollView>
                    <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                        <View style={{ alignItems: 'center', paddingBottom: 20 }}>
                            <Button full loading={state.creating} onPress={handleSubmit} style={{ marginTop: 15, width: '50%', height: 40 }}>
                                {' '}
                                Speichern
                            </Button>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { all } = state.clients;
    const { creating } = state.users.all;
    return { user, creating, allClient: all };
}

const actionCreators = {
    updateUser: userActions.update,
    getAllClient: clientActions.getAll,
};

export default connect(mapState, actionCreators)(UserEdit);
