import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Animated, ScrollView, TouchableOpacity, Vibration, View } from 'react-native';
import { Icon, Image, Text } from '../../components';
import { BaseColor } from '../../config';
import { getImageUrl, resizeFile, scrollToId } from '../../helpers/functions';

export default function FormUploaderInput({ navigation, submitedInput, setInput, isLast, editable }) {
    const animationRef = useRef(new Animated.Value(0));
    const [state, setState] = useState({});
    const highlight = () => {
        scrollToId('submitedInput' + submitedInput.id);
        Vibration.vibrate(100);
        Animated.sequence([
            Animated.timing(animationRef.current, {
                toValue: 1,
                // set the duration
                duration: 500,
                useNativeDriver: false,
            }),
            // delay is optional
            Animated.delay(1000),
            Animated.timing(animationRef.current, {
                toValue: 0,
                duration: 500,
                // useNativeDriver:true, might not work with the all properties that you need to animate. true might improve animation performance
                useNativeDriver: false,
            }),
        ]).start();
        
    };
    const pickImage = async (event) => {
        setState((s) => ({ ...s, loading: true }));
        const files = event.target.files;
        let compressedImages = [];

        for (var i = 0; i < files.length; i++) {
            compressedImages.push({ uri: await resizeFile(files[i]) });
        }
        setInput({ files: [...(submitedInput.files ?? []), ...compressedImages] });
        setState((s) => ({ ...s, loading: false }));
    };

    useEffect(() => {
        setState((s) => ({ ...s, loading: false }));
    }, [submitedInput.files]);

    useEffect(() => {
        if (editable && submitedInput.error) highlight();
    }, [submitedInput.checkInput]);

    return (
        <Animated.View
            
            style={{
                borderBottomColor: BaseColor.grayTransparent,
                borderBottomWidth: isLast ? 0 : 1,
                paddingBottom: 15,
                paddingTop: 10,
                paddingHorizontal: 15,
                paddingLeft: 15,
                backgroundColor: animationRef.current?.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['#fff', '#ccc'],
                }),
            }}
        >
            <div id={'submitedInput' + submitedInput.id}>
                <View style={{ paddingBottom: 5 }}>
                    <Text>
                        {submitedInput.input?.label ?? 'Überschrift'}
                        {submitedInput.input?.required && ' *'}
                    </Text>

                    {submitedInput.input?.description && (
                        <Text grayColor footnote>
                            {submitedInput.input?.description}
                        </Text>
                    )}
                </View>
                <ScrollView horizontal>
                    <View style={{ flexDirection: 'row' }}>
                        {submitedInput.files?.map((image, index) => (
                            <TouchableOpacity
                                key={index}
                                onPress={() =>
                                    navigation.navigate('PreviewImage', {
                                        gallery: submitedInput.files,
                                        title: submitedInput.input?.label,
                                        index,
                                    })
                                }
                                style={{ width: 110, paddingLeft: 3, paddingBottom: 3 }}
                            >
                                <View style={{ borderColor: '#ddd', borderWidth: 1 }}>
                                    <View
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="picture" type="ad" size={50} color={BaseColor.grayTransparent} />
                                    </View>
                                    <Image
                                        source={{ uri: image.id > 0 ? getImageUrl(`${image.path}/${image.name}`) : URL.createObjectURL(image.uri) }}
                                        style={{ width: '100%', height: 120 }}
                                        resizeMode={'cover'}
                                    />
                                </View>
                                {editable && (
                                    <View style={{ position: 'absolute', top: 5, right: 5 }}>
                                        <TouchableOpacity
                                            style={{
                                                backgroundColor: '#000000aa',
                                                height: 30,
                                                width: 30,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: 20,
                                            }}
                                            onPress={() => setInput({ files: submitedInput.files?.filter((image, index1) => index1 != index) })}
                                        >
                                            <Icon name="close" type="ad" color={BaseColor.whiteColor} size={20} />
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </TouchableOpacity>
                        ))}
                        {(submitedInput?.files?.length == 0 || !submitedInput?.files) && !editable && (
                            <View>
                                <Text caption1>Kein Bild hochgeladen</Text>
                            </View>
                        )}
                        {editable && (
                            <TouchableOpacity
                                style={{
                                    height: 122,
                                    width: 110,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: BaseColor.card,
                                    borderColor: BaseColor.grayTransparent,
                                    borderWidth: 1,
                                    marginBottom: 3,
                                    marginLeft: 3,
                                }}
                            >
                                <label htmlFor={'fileInput' + submitedInput.id} style={{ height: '100%', width: '100%', cursor: 'pointer' }}>
                                    <View
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <input
                                            id={'fileInput' + submitedInput.id}
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            onChange={pickImage}
                                            style={{
                                                display: 'none',
                                            }}
                                        />
                                        <View>
                                            {state.loading ? (
                                                <ActivityIndicator color={BaseColor.grayColor + '99'} size={30} />
                                            ) : (
                                                <Icon name="camera-outline" type="ii" size={30} enableRTL={true} />
                                            )}
                                        </View>
                                    </View>
                                </label>
                            </TouchableOpacity>
                        )}
                    </View>
                </ScrollView>
            </div>
        </Animated.View>
    );
}
