import React, { useEffect, useState } from 'react';
import {
    View,
    SafeAreaView,
    KeyboardAvoidingView,
    Linking,
    TouchableOpacity,
    ActivityIndicator,
    RefreshControl,
    Vibration,
    StatusBar,
    Alert,
} from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { Button, Header, TextInput } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { showMessage } from 'react-native-flash-message';
import { storeActions } from '../../redux/actions';
import Section from './Section';
import { useRef } from 'react';
import { Animated } from 'react-native';
import { saveImg } from '../../helpers/apis';
import LoadingFullscreen from '../../components/LoadingFullscreen';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import Menu from '../../components/Menu';
import { formatDate3 } from '../../helpers/functions';

function StoreDetails({ navigation, deleteStore, route, userId, allStores, updateStore, updateLocally, deleteLocally, role }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    const animationRef = useRef(new Animated.Value(0));
    const [state, setState] = useState({
        sections: [],
        ...(allStores[route.params.store?.id] ?? route.params.store),
    });
    const editable = (role == 3 && state?.status != 2) || (role == 1 && state?.status == 2);
    const isVisited = state?.status > 0;

    const highlight = () => {
        Animated.sequence([
            Animated.delay(1000),
            Animated.timing(animationRef.current, {
                toValue: 1,
                // set the duration
                duration: 500,
                useNativeDriver: false,
            }),
            // delay is optional
            Animated.delay(5000),
            Animated.timing(animationRef.current, {
                toValue: 0,
                duration: 500,
                // useNativeDriver:true, might not work with the all properties that you need to animate. true might improve animation performance
                useNativeDriver: false,
            }),
        ]).start();
    };

    const deleteStoreAlert = (id) => {
        Alert.alert({
            title: state?.name + ' löschen',
            message: 'Möchten Sie diese Markt löschen?',
            type: 'error',
            action: [
                {
                    text: 'Löschen',
                    onPress: () => {
                        deleteStore(id, navigation.goBack());
                    },
                },
                { text: 'Abbrechen', onPress: () => {} },
            ],
        });
    };

    const adminAction = () => (
        <View style={{ top: 5, right: 5, position: 'absolute' }}>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                backgroundColor: BaseColor.whiteColor,
                                height: 40,
                                width: 40,
                                borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={[
                    {
                        text: state.status == 1 ? 'Freigeben' : state.status == 2 ? 'Nicht mehr freigeben' : '',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon
                                    name="circle"
                                    type="fa"
                                    size={20}
                                    color={state.status == 1 ? BaseColor.greenDark : state.status == 2 ? BaseColor.yellowColor : ''}
                                />
                            </View>
                        ),
                        rightIcon: () => <Icon name="check" type="ad" size={20} color={BaseColor.whiteColor} />,
                        action: () => {
                            handleSubmit({ ...state, status: state.status == 1 ? 2 : state.status == 2 ? 1 : state.status }, true);
                        },
                    },
                    // {
                    //     text: state.status > 0 ? 'Zurücksetzen' : '',
                    //     leftIcon: () => (
                    //         <View
                    //             style={{
                    //                 backgroundColor: BaseColor.grayLight,
                    //                 height: 45,
                    //                 width: 45,
                    //                 borderRadius: 15,
                    //                 justifyContent: 'center',
                    //                 alignItems: 'center',
                    //             }}
                    //         >
                    //             <Icon name="circle" type="fa" size={20} color={BaseColor.errorColor} />
                    //         </View>
                    //     ),
                    //     action: () => handleSubmit({ ...state, status: 0 }, true),
                    // },
                    {
                        text: 'Bearbeiten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="edit" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate('StoreEdit', { store: state }),
                    },
                    !isVisited || userId == 19
                        ? {
                              text: 'Löschen',
                              leftIcon: () => (
                                  <View
                                      style={{
                                          backgroundColor: BaseColor.grayLight,
                                          height: 45,
                                          width: 45,
                                          borderRadius: 15,
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                      }}
                                  >
                                      <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                                  </View>
                              ),
                              action: () => deleteStoreAlert(state.id),
                          }
                        : [],
                ]}
            />
        </View>
    );
    useEffect(() => {
        highlight();
        onRefresh();
    }, []);
    useEffect(() => {
        updateLocally(state);
    }, [state]);

    function mountAction(actionInput) {
        let sections = [];
        let action = actionInput;
        actionInput.submitedForm?.submitedInputs?.forEach((submitedInput) => {
            sections[submitedInput?.input?.section ?? 0] = {
                ...(sections[submitedInput.input?.section ?? 0] ?? {}),
                submitedInputs: [
                    ...(sections[submitedInput.input?.section ?? 0]?.submitedInputs ?? []),
                    {
                        ...submitedInput,
                        value:
                            state.status == null || state.status == 0
                                ? state.sections[submitedInput.input?.section - 1]?.submitedInputs?.filter((i) => i.id == submitedInput.id)[0]?.value
                                : submitedInput.value,
                        files:
                            state.status == null || state.status == 0
                                ? state.sections[submitedInput.input?.section - 1]?.submitedInputs?.filter((i) => i.id == submitedInput.id)[0]?.files
                                : submitedInput.files,
                    },
                ],
            };
        });
        sections.shift();
        return { ...action, sections };
    }

    const onRefresh = () => {
        setState((s) => ({
            ...s,
            executionDate:
                s?.executionDate ??
                (editable ? (!(s.status >= 1) ? new Date().toLocaleDateString() : new Date(s.visitedDate).toLocaleDateString()) : ''),
            loadingError: '',
            loading: true,
        }));
        storeActions.getSingle(state.id, (resp) => {
            if (resp == 'error' || resp.response == null) {
                setState((s) => ({ ...s, loadingError: 'Fehler bein Laden', loading: false }));
            } else {
                setState((s) => ({ ...s, ...mountAction(resp.response), loading: false }));
            }
        });
    };
    const checkIputs = () => {
        if (role == 1) return true;
        let submitable = true;
        let newSections = state.sections?.map((section) => ({
            ...section,
            submitedInputs: section.submitedInputs?.map((submitedInput) => {
                const error =
                    (!submitedInput.input.required ||
                        (submitedInput.input.required && (submitedInput.value?.length || submitedInput.files?.length > 0))) > 0
                        ? false
                        : true;
                submitable &= !error;
                return { ...submitedInput, error, checkInput: !submitedInput.checkInput };
            }),
        }));
        setState((s) => ({
            ...s,
            sections: newSections,
            executionDateError: !(s.executionDate?.length > 0),
        }));

        return submitable;
    };
    function handleSubmit(state, withoutNavigation) {
        let globalInputs = [];
        let submitable = checkIputs();

        for (let index = 0; index < state.sections.length; index++) {
            globalInputs = globalInputs.concat(state.sections[index].submitedInputs);
        }

        if (submitable) {
            const store = {
                id: state.id,
                status: state.status > 0 ? state.status : 1,
                executionDate: state.executionDate,
                submitedForm: { ...state.submitedForm, submitedInputs: globalInputs },
            };
            setState((s) => ({ ...s, updating: true }));

            updateStore(
                store,
                async (submitedInputs) => {
                    let uploaderInputs = submitedInputs?.sort((a, b) => a.id - b.id);
                    let globalUploaderInputs = globalInputs?.filter((i) => i.input?.type == 'Uploader').sort((a, b) => a.id - b.id);

                    for (let index_1 = 0; index_1 < uploaderInputs.length; index_1++) {
                        const si = uploaderInputs[index_1];

                        let files = si?.files.sort((a, b) => a.id - b.id);
                        let filesWithUrl = globalUploaderInputs[index_1]?.files?.filter((f) => f.uri).sort((a, b) => a.id - b.id);
                        let images = [];
                        for (let index = 0; index < files.length; index++) {
                            const file = files[index];
                            if (filesWithUrl[index]?.uri) {
                                images.push({ name: file.name, content: filesWithUrl[index].uri });
                            }
                        }

                        if (images.length > 0) {
                            await uploadImage(images, files[0].path);
                        }
                    }
                    setState((s) => ({ ...s, loadingError: '', updating: false }));
                    deleteLocally(route.params.store?.id);
                    showMessage({
                        message: 'Speichern erfolgreich',
                        type: 'success',
                        icon: 'success',
                    });
                    if (withoutNavigation) {
                        onRefresh();
                    } else {
                        navigation.goBack();
                    }
                },
                (error) => {
                    setState((s) => ({ ...s, updating: false }));
                    !withoutNavigation &&
                        showMessage({
                            message: 'Speichern nicht möglich',
                            description: 'Versuchen Sie erneut',
                            type: 'warning',
                            icon: 'warning',
                            autoHide: false,
                        });
                }
            );
        }
        return;
    }
    const uploadImage = async (files, path) => {
        await saveImg(files, path)
            .then((res) => {})
            .catch((error) => {
                console.error(error);
                setState((s) => ({ ...s, updating: false }));
                showMessage({
                    message: 'Fehler beim Hochladen! Überprüfen Sie Ihre Verbindung oder versuchen Sie es später erneut',
                    icon: 'warning',
                });
                return error;
            });
    };
    const headerSecondary = () => (
        <View>
            <View
                style={{
                    backgroundColor: BaseColor.whiteColor,
                    padding: 15,
                    borderBottomColor: BaseColor.grayTransparent,
                    borderBottomWidth: 1,
                }}
            >
                <View>
                    <View>
                        <View style={{ paddingBottom: 10 }}>
                            <Text bold title3>
                                {state?.name}
                            </Text>
                            <Text>{`${state.street}, ${state.postalCode ? state.postalCode + ' ' : ''}${state.city}`}</Text>
                        </View>
                        <View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                <View style={{ flex: 1 }}>
                                    <Text>Filialnummer: </Text>
                                </View>
                                <View>
                                    <Text>{state.filialnumber ?? '---'}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                                <View style={{ flex: 1 }}>
                                    <Text>Area manager: </Text>
                                </View>
                                <View>
                                    <Text>{state.areaManager ?? '---'}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                                <View style={{ flex: 1 }}>
                                    <Text>Planning: </Text>
                                </View>
                                <View>
                                    <Text>{state.planning ?? '---'}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                                <View style={{ flex: 1 }}>
                                    <Text>Center: </Text>
                                </View>
                                <View>
                                    <Text>{state.center ?? '---'}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                                <View style={{ flex: 1 }}>
                                    <Text>ADM: </Text>
                                </View>
                                <View>
                                    <Text>{state.user ? state.user.firstname + ' ' + state.user.lastname : '---'}</Text>
                                </View>
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <View style={{ flex: 1, alignItems: 'flex-start' }}></View>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    marginTop: 10,

                                    borderRadius: 20,
                                    backgroundColor: BaseColor.grayLight,
                                }}
                            >
                                <TouchableOpacity
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 5,
                                        borderRightWidth: 1,
                                        borderColor: BaseColor.grayTransparent,
                                    }}
                                    onPress={() => state.phoneNumber && Linking.openURL(`tel:${state.phoneNumber}`)}
                                >
                                    <Icon name="phone" type="ad" size={20} color={state.phoneNumber ? 'black' : BaseColor.grayTransparent} />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{ paddingVertical: 5, paddingHorizontal: 15 }}
                                    onPress={() => state.email && Linking.openURL(`mailto:${state.email}`)}
                                >
                                    <Icon name="mail" type="ad" size={20} color={state.email ? 'black' : BaseColor.grayTransparent} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
                {state?.description && <Text>{state?.description}</Text>}
            </View>
        </View>
    );
    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title={state.name}
                subTitle={`${state?.street} ${state?.city}`}
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
                renderRight={role == 1 ? adminAction : undefined}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1, backgroundColor: BaseColor.grayLight }}
                >
                    <ScrollView
                        refreshControl={
                            <RefreshControl colors={[BaseColor.primary]} tintColor={BaseColor.primary} refreshing={false} onRefresh={onRefresh} />
                        }
                    >
                        {headerSecondary()}
                        <View style={{ padding: 15, paddingBottom: 80 }}>
                            <View style={{ paddingBottom: 10 }}>
                                <Text headline bold>
                                    Abfrage
                                </Text>
                                {state.formError && (
                                    <Text footnote errorColor>
                                        {state.formError}
                                    </Text>
                                )}
                            </View>
                            <View
                                style={{
                                    backgroundColor: BaseColor.whiteColor,
                                    padding: 10,
                                    marginBottom: 15,
                                    borderBottomWidth: 1,
                                    borderBottomColor: BaseColor.grayTransparent,
                                }}
                            >
                                <View style={{ paddingBottom: 5 }}>
                                    <Text>{'Ausgeführt am *'}</Text>
                                </View>
                                <TextInput
                                    editable={(role == 3 && state.status != 2) || (role == 1 && state.status == 2)}
                                    onChangeText={(v) => setState((s) => ({ ...s, executionDate: v }))}
                                    onFocus={() => setState((s) => ({ ...s, executionDateError: '' }))}
                                    placeholder="Ihre Antwort"
                                    success={!state?.executionDateError}
                                    value={state?.executionDate}
                                    style={{ borderRadius: 0 }}
                                    textError={state?.executionDateError && 'Feld erforderlich'}
                                />
                            </View>
                            {state.sections?.map((section, index) => (
                                <View key={'section' + index}>
                                    <Section
                                        editable={(role == 3 && state.status != 2) || (role == 1 && state.status == 2)}
                                        section={{ ...section, index }}
                                        navigation={navigation}
                                        setSection={(newSection) =>
                                            setState((s) => ({
                                                ...s,
                                                sections: s.sections.map((sec, index1) => (index1 == index ? { ...sec, ...newSection } : sec)),
                                            }))
                                        }
                                    />
                                </View>
                            ))}
                        </View>

                        {state.loading ? (
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <ActivityIndicator color={BaseColor.primary} size={'large'} />
                            </View>
                        ) : (
                            state.loadingError && (
                                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    <Text errorColor>Fehler beim Laden</Text>
                                    <TouchableOpacity
                                        onPress={onRefresh}
                                        style={{ flexDirection: 'row', padding: 10, marginTop: 10, alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <Icon name="refresh" type="ii" size={20} />
                                        <Text>Nochmal versuchen</Text>
                                    </TouchableOpacity>
                                </View>
                            )
                        )}
                    </ScrollView>
                    {(role == 3 && state.status != 2) || (role == 1 && state.status == 2) ? (
                        <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                            <View style={{ alignItems: 'center', padding: 15, paddingTop: 0, flexDirection: 'row' }}>
                                <View style={{ paddingRight: 15, flex: 1 }}>
                                    <Button onPress={() => navigation.goBack()} style={{ height: 40, backgroundColor: '#000' }}>
                                        {' '}
                                        Zurück
                                    </Button>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Button onPress={() => handleSubmit(state)} style={{ height: 40 }}>
                                        {' '}
                                        Speichern
                                    </Button>
                                </View>
                            </View>
                        </View>
                    ) : (
                        role == 1 &&
                        state.status == 1 && (
                            <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                                <View style={{ alignItems: 'center', padding: 15, paddingTop: 0, flexDirection: 'row' }}>
                                    <View style={{ paddingRight: 15, flex: 1 }}>
                                        <Button onPress={() => navigation.goBack()} style={{ height: 40, backgroundColor: '#000' }}>
                                            Zurück
                                        </Button>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Button
                                            onPress={() => handleSubmit({ ...state, status: state.status == 1 ? 2 : state.status }, true)}
                                            style={{ height: 40, backgroundColor: BaseColor.greenDark }}
                                        >
                                            Freigeben
                                        </Button>
                                    </View>
                                </View>
                            </View>
                        )
                    )}
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: 3,
                            right: 0,
                            backgroundColor:
                                state.status == null || state.status == 0
                                    ? BaseColor.errorColor
                                    : state.status == '1'
                                    ? BaseColor.yellowColor
                                    : BaseColor.greenDark,
                        }}
                    ></View>
                    {!(state.status > 0) ? (
                        <Animated.View
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: BaseColor.errorColor,
                                alignItems: 'center',
                                opacity: animationRef.current,
                            }}
                        >
                            <Text footnote whiteColor>
                                Markt noch nicht besucht
                            </Text>
                        </Animated.View>
                    ) : state.status == 1 ? (
                        <Animated.View
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: BaseColor.yellowColor,
                                alignItems: 'center',
                                opacity: animationRef.current,
                            }}
                        >
                            <Text footnote whiteColor>
                                Markt wurde besucht
                            </Text>
                        </Animated.View>
                    ) : (
                        state.status == 2 && (
                            <Animated.View
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    backgroundColor: BaseColor.greenDark,
                                    alignItems: 'center',
                                    opacity: animationRef.current,
                                }}
                            >
                                <Text footnote whiteColor>
                                    Markt wurde bestätigt
                                </Text>
                            </Animated.View>
                        )
                    )}
                </KeyboardAvoidingView>
            </SafeAreaView>
            {state.updating && (
                <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <LoadingFullscreen />
                </View>
            )}
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { all } = state.stores;
    return { role: user?.roles[0], userId: user.id, allStores: all };
}

const actionCreators = {
    deleteLocally: storeActions.deleteLocally,
    deleteStore: storeActions.delete,
    updateLocally: storeActions.updateLocally,
    updateStore: storeActions.update,
};

export default connect(mapState, actionCreators)(StoreDetails);
