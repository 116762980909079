import React, { useEffect } from 'react';
import { ActivityIndicator, View, Alert } from 'react-native';
import { userActions } from '@actions';
import { useDispatch, useSelector } from 'react-redux';
import { offlineStorageSelector, userSelector } from '../../selectors';
import * as Font from 'expo-font';
import * as Device from 'expo-device';
import styles from './styles';
import { BaseColor } from '../../config';
import * as SplashScreen from 'expo-splash-screen';
import { useCallback } from 'react';
import { offlineStorageActions } from '../../redux/actions';

SplashScreen.preventAutoHideAsync();
export default function Loading({ navigation }) {
    const dispatch = useDispatch();
    const user = useSelector(userSelector.user);
    const device = useSelector(offlineStorageSelector.device);

    /**
     *
     * Override Alert
     */
    Alert.alert = ({ title, message, action, option, type }) => {
        navigation.navigate('Alert', {
            type: type ?? 'warning',
            title: title ?? '',
            message: message ?? '',
            action,
            option: option ?? { cancelable: true },
        });
    };

    /**
     *
     * Process when open app
     */
    const onProcess = async () => {
        await Font.loadAsync({
            'Roboto-Black': require('app/assets/fonts/Roboto-Black.ttf'),
            'Roboto-BlackItalic': require('app/assets/fonts/Roboto-BlackItalic.ttf'),
            'Roboto-Bold': require('app/assets/fonts/Roboto-Bold.ttf'),
            'Roboto-BoldItalic': require('app/assets/fonts/Roboto-BoldItalic.ttf'),
            'Roboto-Italic': require('app/assets/fonts/Roboto-Italic.ttf'),
            'Roboto-Light': require('app/assets/fonts/Roboto-Light.ttf'),
            'Roboto-LightItalic': require('app/assets/fonts/Roboto-LightItalic.ttf'),
            'Roboto-Medium': require('app/assets/fonts/Roboto-Medium.ttf'),
            'Roboto-MediumItalic': require('app/assets/fonts/Roboto-MediumItalic.ttf'),
            'Roboto-Regular': require('app/assets/fonts/Roboto-Regular.ttf'),
            Roboto: require('app/assets/fonts/Roboto-Regular.ttf'),
            'Roboto-Thin': require('app/assets/fonts/Roboto-Thin.ttf'),
            'Roboto-ThinItalic': require('app/assets/fonts/Roboto-ThinItalic.ttf'),
        });

        dispatch(
            userActions.clearStorage(false, () => {
                //if (user?.id) {
                //navigate()
                if (!device) {
                    Device.getDeviceTypeAsync().then((response) => {
                        dispatch(offlineStorageActions.simpleUpdate({ device: response }));
                    });
                }
                if (user?.id) {
                    navigate();
                    dispatch(userActions.getUserAuth());
                } else {
                    dispatch(userActions.getUserAuth(navigate));
                }

                // } else {
                //     //navigation.replace('SignIn');
                // }
            })
        );
    };

    let navigate = () => {
        navigation.replace('Main');
    };

    useEffect(() => {
        onProcess();
    }, []);
    const onLayoutRootView = useCallback(async () => {
        //await SplashScreen.hideAsync();
    }, []);

    return (
        <View style={styles.container} onLayout={onLayoutRootView}>
            <View style={{ alignItems: 'center', width: '100%' }}>
                <ActivityIndicator size={'large'} color={BaseColor.primary} />
            </View>
        </View>
    );
}
