export const storeConstants = {
    GETALL_REQUEST: 'STORE_GETALL_REQUEST',
    GETALL_SUCCESS: 'STORE_GETALL_SUCCESS',
    GETALL_FAILURE: 'STORE_GETALL_FAILURE',

    GETBYID_REQUEST: 'STORE_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'STORE_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'STORE_GETBYID_FAILURE',

    DELETE_REQUEST: 'STORE_DELETE_REQUEST',
    DELETE_SUCCESS: 'STORE_DELETE_SUCCESS',
    DELETE_FAILURE: 'STORE_DELETE_FAILURE',

    CREATE_REQUEST: 'STORE_CREATE_REQUEST',
    CREATE_SUCCESS: 'STORE_CREATE_SUCCESS',
    CREATE_FAILURE: 'STORE_CREATE_FAILURE',

    UPDATE_REQUEST: 'STORE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'STORE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'STORE_UPDATE_FAILURE',

    LOGOUT_SUCCESS: 'STORE_LOGOUT_SUCCESS',
    CHANGE_CURRENT: 'STORE_CHANGE_CURRENT',
};
