export const clientConstants = {
    GETALL_REQUEST: 'CLIENTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CLIENTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CLIENTS_GETALL_FAILURE',

    GETBYID_REQUEST: 'CLIENTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'CLIENTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'CLIENTS_GETBYID_FAILURE',

    DELETE_REQUEST: 'CLIENTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CLIENTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CLIENTS_DELETE_FAILURE',

    CREATE_REQUEST: 'CLIENTS_CREATE_REQUEST',
    CREATE_SUCCESS: 'CLIENTS_CREATE_SUCCESS',
    CREATE_FAILURE: 'CLIENTS_CREATE_FAILURE',

    UPDATE_REQUEST: 'CLIENTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CLIENTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CLIENTS_UPDATE_FAILURE',

    LOGOUT_SUCCESS: 'CLIENTS_LOGOUT_SUCCESS',
    CHANGE_CURRENT: 'CLIENTS_CHANGE_CURRENT',
};
