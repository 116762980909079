import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/FontAwesome5';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Feather from 'react-native-vector-icons/Feather';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Octicons from 'react-native-vector-icons/Octicons';
import styles from './styles';

export default function Index(props) {
    const { style, enableRTL, type, ...rest } = props;
    const layoutStyle = enableRTL ? styles.styleRTL : {};
    switch (type) {
        case 'mi':
            return <MaterialIcons style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
        case 'mci':
            return <MaterialCommunityIcons style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
        case 'fa':
            return <FontAwesome style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
        case 'ft':
            return <Feather style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
        case 'ad':
            return <AntDesign style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
        case 'ii':
            return <Ionicons style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
        case 'oi':
            return <Octicons style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
        default:
            return <Icon style={StyleSheet.flatten([style, layoutStyle])} {...rest} />;
    }
}

Index.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    enableRTL: PropTypes.bool,
};

Index.defaultProps = {
    style: {},
    enableRTL: false,
};
