import React from 'react';
import { View, TouchableHighlight, ActivityIndicator, Alert } from 'react-native';
import { Text, Icon } from '@components';
import { BaseColor } from '@config';
import styles from './styles';
import { Placeholder, PlaceholderLine, PlaceholderMedia, Fade } from 'rn-placeholder';
import { connect } from 'react-redux';
import Menu from '../Menu';
import { actionActions, userActions } from '../../redux/actions';
import { compareDate, dateWatch, formatDate, formatDate2 } from '../../helpers/functions';
import { useState } from 'react';

function ActionListItem(props) {
    const { item, userId, loading, style, navigation, deleteAction, role, unclickable } = props;
    const [deleting, setDeleting] = useState();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const deleteActionAlert = (id) => {
        Alert.alert({
            title: item?.name + ' löschen',
            message: 'Achtung!\nBeim Löschen werden alle Märktedaten und Märktebilder gelöscht',
            type: 'error',
            action: [
                {
                    text: 'Löschen',
                    onPress: () => {
                        setDeleting((d) => ({ value: true, deleted: false }));
                        deleteAction(
                            id,
                            () => setDeleting((d) => ({ value: false, deleted: true })),
                            () => setDeleting((d) => ({ value: false, deleted: false }))
                        );
                    },
                },
                { text: 'Abbrechen', onPress: () => {} },
            ],
        });
    };
    const adminAction = () => (
        <View style={{ top: 0, right: 0, bottom: 15, position: 'absolute', justifyContent: 'center' }}>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                //backgroundColor: BaseColor.whiteColor,
                                height: 40,
                                width: 40,
                                //borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                            //Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={[
                    {
                        text: 'Zuweisung verwalten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="addusergroup" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate({ name: 'AssignClient', params: { action: item } }),
                    },
                    {
                        text: 'Bearbeiten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="edit" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate({ name: 'ActionEdit', params: { action: item } }),
                    },
                    userId == 19
                        ? {
                              text: 'Löschen',
                              leftIcon: () => (
                                  <View
                                      style={{
                                          backgroundColor: BaseColor.grayLight,
                                          height: 45,
                                          width: 45,
                                          borderRadius: 15,
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                      }}
                                  >
                                      <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                                  </View>
                              ),
                              action: () => deleteActionAlert(item?.id),
                          }
                        : [],
                ]}
            />
        </View>
    );
    const renderGrid = () => {
        if (loading) {
            return (
                <View style={[style, { marginBottom: 5 }]}>
                    <Placeholder Animation={Fade}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                                <PlaceholderLine style={{ height: 100, borderRadius: 0 }} />
                            </View>
                        </View>
                    </Placeholder>
                </View>
            );
        }
        return (
            <View>
                <TouchableHighlight
                    underlayColor={deleting?.deleted ? '#fff' : unclickable ? '#00000000' : '#00000010'}
                    activeOpacity={1}
                    style={{
                        overflow: 'hidden',
                        padding: 15,
                        borderBottomWidth: 1,
                        borderBottomColor: BaseColor.grayTransparent,
                        marginBottom: 13,
                        backgroundColor: BaseColor.whiteColor,
                        opacity: deleting?.deleted ? 0.5 : 1,
                    }}
                    onPress={() =>
                        !deleting?.deleted &&
                        !unclickable &&
                        navigation.navigate('StoreList', {
                            action: item,
                        })
                    }
                >
                    <View>
                        <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                            <View>
                                <View>
                                    <Text semibold>{item?.name}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    {compareDate(currentDate, item?.endDate) >= 0 ? (
                                        <Text caption1 errorColor>
                                            Vorbei
                                        </Text>
                                    ) : compareDate(item?.startDate) >= 0 ? (
                                        <Text caption1 style={{ color: BaseColor.greenColor }}>
                                            Läuft gerade
                                        </Text>
                                    ) : (
                                        <Text caption1 primaryColor>
                                            Beginnt {formatDate(item?.startDate)}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                        {item?.description && <Text>{item?.description}</Text>}
                        <View style={{ alignItems: 'flex-start', paddingTop: 5 }}>
                            <View style={{ paddingHorizontal: 10, paddingVertical: 5, borderRadius: 20, backgroundColor: BaseColor.grayLight }}>
                                <Text footnote>
                                    {item?.startDate && formatDate2(item?.startDate)} - {item?.startDate && formatDate2(item?.endDate)}
                                </Text>
                            </View>
                        </View>
                    </View>
                </TouchableHighlight>
                {role == 1 && !deleting?.deleted && adminAction()}
                {item?.deleting && (
                    <View style={{ position: 'absolute', top: -0, left: 0, right: 0, bottom: 15 }}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#00000080' }}>
                            <ActivityIndicator size={'large'} color={BaseColor.whiteColor} />
                        </View>
                    </View>
                )}
                {/* {item?.id == user.id && (
                    <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: 5, backgroundColor: BaseColor.primary }}></View>
                )} */}
                {/* {pressed && (
                    <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', transform: [{ scale: 1.1 }] }}>
                        <View style={{ flex: 1, backgroundColor: '#00000010' }}></View>
                    </View>
                )} */}
            </View>
        );
    };

    return renderGrid();
}

function mapState(state) {
    const { user } = state.users;
    //const { deleting } = state.users?.all;
    return { role: user?.roles[0], userId: user.id };
}

const actionCreators = {
    deleteAction: actionActions.delete,
};

export default connect(mapState, actionCreators)(ActionListItem);
