import React from 'react';
import { View } from 'react-native';
import { BaseColor } from '../../config';

export default function DatePicker({ date, onChange, maximumDate, minimumDate, dateError }) {
    const onChangeDate = (event) => {
        const selectedDate = event.target.value;
        const currentDate = selectedDate || date;
        onChange(currentDate);
    };
    return (
        <View>
            <input
                style={{
                    color: dateError ? BaseColor.errorColor : date ? BaseColor.text : BaseColor.grayColor,
                    height: 40,
                    border: 'none',
                    backgroundColor: BaseColor.grayLight,
                    fontSize: '15px',
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
                type="date"
                id="dateTimePicker"
                max={maximumDate?.split('T')[0] ?? ''}
                min={minimumDate?.split('T')[0] ?? ''}
                onChange={onChangeDate}
                value={date?.split('T')[0] ?? ''}
            />
        </View>
    );
}
