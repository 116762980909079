import React, { useState } from 'react';
import { View, ScrollView, TouchableHighlight, Platform, Linking, Alert } from 'react-native';
import { Icon, Text } from '@components';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '@actions';
import { BaseColor, BaseStyle } from '../../config';
import { ActivityIndicatorFullScreen } from '../../components';
import { userSelector } from '../../selectors';

export default function SettingsTag({ navigation, isAdmin }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const user = useSelector(userSelector.user);
    const onLogout = () => {
        Alert.alert({
            title: 'Ausloggen',
            message: 'Möchten Sie sich ausloggen?',
            type: 'error',
            action: [
                {
                    text: 'Ja',
                    onPress: () => {
                        setLoading(true);
                        dispatch(
                            userActions.logout(() => {
                                setLoading(false);
                                //navigation.replace('SignIn');
                            })
                        );
                    },
                },
                { text: 'Abbrechen', onPress: () => {} },
            ],
        });
    };
    const items = [
        {
            icon: 'team',
            type: 'ad',
            backgroundColor: BaseColor.grayLight,
            color: BaseColor.text,
            label: 'Personal verwalten',
            onPress: () => {
                navigation.navigate('UserList');
            },
            isAdmin: true,
        },
        {
            icon: 'file1',
            type: 'ad',
            backgroundColor: BaseColor.grayLight,
            color: BaseColor.text,
            label: 'CSV Dateien',
            onPress: () => {
                navigation.navigate('CSVFiles');
            },
            isAdmin: true,
        },
        {
            icon: 'edit',
            type: 'ad',
            backgroundColor: BaseColor.grayLight,
            color: BaseColor.text,
            label: 'Profil bearbeiten',
            onPress: () => {
                navigation.navigate('ProfileEdit');
            },
            isAdmin: false,
        },
        {
            icon: 'exclamationcircleo',
            type: 'ad',
            backgroundColor: BaseColor.grayLight,
            color: BaseColor.text,
            label: 'Problem melden!',
            onPress: () => Linking.openURL('mailto:webservices@gate.de'),
            isAdmin: false,
        },
        {
            icon: 'logout',
            type: 'ad',
            backgroundColor: BaseColor.grayLight,
            color: BaseColor.text,
            label: 'Ausloggen',
            onPress: onLogout,
            isAdmin: false,
        },
    ];
    return (
        <View style={{ paddingVertical: 20 }}>
            {items.map(
                (item, key) =>
                    ((item.isAdmin && user?.roles[0] == 1) || !item.isAdmin) && (
                        <TouchableHighlight
                            key={key}
                            underlayColor={item.backgroundColor}
                            style={[
                                //styles.profileItem,
                                {
                                    borderBottomColor: BaseColor.grayLight,
                                    borderBottomWidth: 1,
                                    paddingHorizontal: 20,
                                    paddingVertical: 10,
                                },
                            ]}
                            onPress={item.onPress}
                        >
                            <View style={{ flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
                                <View
                                    style={[
                                        {
                                            marginRight: 10,
                                            borderRadius: 15,
                                            height: 45,
                                            width: 45,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: item.backgroundColor,
                                        },
                                    ]}
                                >
                                    <Icon name={item.icon} type={item.type} size={25} color={item.color} enableRTL={true} />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text callout1>{item.label}</Text>
                                </View>
                                {item.isAdmin && user?.roles[0] == 1 && (
                                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <View
                                            style={{
                                                paddingHorizontal: 10,
                                                paddingVertical: 5,
                                                borderRadius: 10,
                                                backgroundColor: '#000',
                                            }}
                                        >
                                            <Text primaryColor semibold footnote>
                                                Admin
                                            </Text>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </TouchableHighlight>
                    )
            )}
        </View>
    );
}
