import React, { useEffect, useRef, useState } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView, TouchableOpacity, Alert, Linking } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { AlertComponent, Button, Header, Image, TextInput } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';
import Menu from '../../components/Menu';
import * as ImagePicker from 'expo-image-picker';
import { saveImg } from '../../helpers/apis';
import { showMessage } from 'react-native-flash-message';
import { compressImage } from '../../helpers/functions';
import CostumModal from '../../components/CostumModal';
import PostOptions from '../../components/PostOptions';
import { clientActions, userActions } from '../../redux/actions';

const roles = [
    {
        name: 'Projektleiter',
        value: 1,
    },
    {
        name: 'Kunde',
        value: 2,
    },
    {
        name: 'Außendienstmitarbeiter',
        value: 3,
    },
];

function UserDetails({ navigation, getAllClient, deleteUser, route, loggedUser }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });
    const user = route.params.user;

    // useEffect(() => {
    //     getAllClient();
    // }, []);

    const attributeList = [
        { label: 'Id', value: user?.id, show: true },
        { label: 'Vorname', value: user?.firstname, show: true },
        { label: 'Nachname', value: user?.lastname, show: true },
        { label: 'E-Mail', value: user?.email, show: true },
        { label: 'Telefonnummer', value: user?.phonenumber, show: true },
        { label: 'Passwort', value: user?.password, show: true },
        { label: 'Rolle', value: user?.roles[0]?.name, show: true },
        { label: 'Kunde', value: user.client?.name, show: user?.roles[0]?.value == 2 },
    ];
    const deleteUserAlert = (id) => {
        Alert.alert({
            title: user.lastname + ' löschen',
            message: 'Möchten Sie diesen Benutzer löschen?',
            type: 'warnning',
            action: [
                { text: 'Löschen', onPress: () => deleteUser(id, () => navigation.goBack()) },
                { text: 'Abbrechen', onPress: () => {} },
            ],
        });
    };
    const adminAction = () => (
        <View>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                //backgroundColor: BaseColor.whiteColor,
                                height: 40,
                                width: 40,
                                //borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                            //Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={[
                    {
                        text: 'Bearbeiten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="edit" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate({ name: 'UserEdit', params: { user } }),
                    },
                    {
                        text: 'Löschen',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => deleteUserAlert(user.id),
                    },
                ]}
            />
        </View>
    );
    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title={`${user?.firstname} ${user?.lastname} ${user.id == loggedUser.id ? '(Du)' : ''}`}
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
                renderRight={user.id !== 19 || loggedUser.id == 19 ? adminAction : () => {}}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <ScrollView>
                        <View style={{ padding: 20 }}>
                            {attributeList.map((attribute) => {
                                if (attribute.show) {
                                    return (
                                        <View
                                            style={{
                                                borderBottomColor: BaseColor.grayLight,
                                                borderBottomWidth: 1,
                                                paddingVertical: 10,
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <View style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'center' }}>
                                                <Text>{attribute.label}</Text>
                                            </View>
                                            <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
                                                <Text grayColor>{attribute.value ? attribute.value : '---'}</Text>
                                            </View>
                                        </View>
                                    );
                                }
                            })}
                        </View>
                    </ScrollView>
                    <View
                        style={{
                            position: 'absolute',
                            bottom: 20,
                            width: '100%',
                            alignItems: 'center',
                            paddingHorizontal: 20,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                            }}
                        >
                            {user.phonenumber && (
                                <Button
                                    style={{
                                        flex: 1,
                                        marginRight: 20,
                                        borderRightWidth: 1,
                                        borderColor: BaseColor.grayTransparent,
                                        height: 40,
                                        backgroundColor: 'black',
                                    }}
                                    onPress={() => user.phonenumber && Linking.openURL(`tel:${user.phonenumber}`)}
                                    icon={<Icon name="phone" type="ad" size={20} color={BaseColor.primary} />}
                                >
                                    <Text primaryColor> Anrufen</Text>
                                </Button>
                            )}
                            {user.email && (
                                <Button
                                    style={{ flex: 1, paddingLeft: 10, height: 40, backgroundColor: 'black' }}
                                    onPress={() => user.email && Linking.openURL(`mailto:${user.email}`)}
                                    icon={<Icon name="mail" type="ad" size={20} color={BaseColor.primary} />}
                                >
                                    <Text primaryColor> E-Mail</Text>
                                </Button>
                            )}
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { all } = state.clients;
    const { creating } = state.users.all;
    return { loggedUser: user, creating, allClient: all };
}

const actionCreators = {
    updateUser: userActions.update,
    deleteUser: userActions.delete,
    getAllClient: clientActions.getAll,
};

export default connect(mapState, actionCreators)(UserDetails);
