import { storeConstants } from '../constants';
import { storeService } from '../services';
import { showMessage } from 'react-native-flash-message';

export const storeActions = {
    create,
    update,
    getAll,
    delete: _delete,
    getSingle,
    getAllWR,
    deleteLocally,
    updateLocally,
    createLocally,
};

function create(store, successCallback, errorCallback) {
    return (dispatch) => {
        dispatch(request({ store }));
        storeService.create(store).then(
            (store) => {
                if (store != null) {
                    //dispatch(success(store));
                    successCallback && successCallback(store);
                } else {
                    //dispatch(failure(''));
                    errorCallback && errorCallback();
                }
            },
            (error) => {
                //(failure(error.toString()));
                errorCallback && errorCallback(error);
            }
        );
    };

    function request(store) {
        return { type: storeConstants.CREATE_REQUEST, store };
    }
    function success(store) {
        return { type: storeConstants.CREATE_SUCCESS, store };
    }
    function failure(error) {
        return { type: storeConstants.CREATE_FAILURE, error };
    }
}

function update(store, callback, errorCallback) {
    return (dispatch) => {
        //dispatch(request({ store }));
        storeService.update(store).then(
            (store) => {
                //dispatch(success(store));
                callback && callback(store);
            },
            (error) => {
                //dispatch(failure(error.toString()));
                errorCallback && errorCallback(error);
            }
        );
    };

    function request(store) {
        return { type: storeConstants.UPDATE_REQUEST, store };
    }
    function success(store) {
        return { type: storeConstants.UPDATE_SUCCESS, store };
    }
    function failure(error) {
        return { type: storeConstants.UPDATE_FAILURE, error };
    }
}
function getAllWR(filter, request, success, failure) {
    request();
    storeService.getAll(filter).then(
        (stores) => success(stores),
        (error) => failure(error?.toString())
    );
}
function getAll(filter, callback, errorCallback) {
    return (dispatch) => {
        dispatch(request());
        storeService.getAll(filter).then(
            (stores) => {
                dispatch(success(stores));
                callback && callback(stores);
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: storeConstants.GETALL_REQUEST };
    }
    function success(stores) {
        return { type: storeConstants.GETALL_SUCCESS, stores };
    }
    function failure(error) {
        return { type: storeConstants.GETALL_FAILURE, error };
    }
}

function _delete(id, successCallback, failureCallback) {
    return (dispatch) => {
        //dispatch(request(id));
        storeService.delete(id).then(
            (resp) => {
                showMessage({
                    message: 'Markt wurde gelöscht',
                    icon: 'success',
                });
                //dispatch(success(id));
                successCallback && successCallback();
            },
            (error) => {
                showMessage({
                    message: 'Fehler beim Löschen',
                    icon: 'warning',
                });
                failureCallback && failureCallback();
                //dispatch(failure(id, error.toString()));
            }
        );
    };

    function request(id) {
        return { type: storeConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: storeConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: storeConstants.DELETE_FAILURE, id, error };
    }
}

function getSingle(id, callback) {
    storeService.getById(id).then(
        (store) => {
            callback && callback(store);
        },
        (error) => {
            showMessage({
                message: 'Fehler beim Laden',
                description: 'Bitte versuchen Sie später nochmal',
                type: 'warning',
                icon: 'warning',
            });
            callback && callback('error');
        }
    );
}

function updateLocally(store) {
    return (dispatch) => {
        dispatch(success(store));
    };
    function success(store) {
        return { type: storeConstants.UPDATE_SUCCESS, store };
    }
}

function deleteLocally(id) {
    return (dispatch) => {
        dispatch(success(id));
    };
    function success(id) {
        return { type: storeConstants.DELETE_SUCCESS, id };
    }
}

function createLocally(store) {
    return (dispatch) => {
        dispatch(success(store));
    };
    function success(store) {
        return { type: storeConstants.CREATE_SUCCESS, store };
    }
}
