import React, { useCallback, useState } from 'react';
import { View, RefreshControl, Platform, KeyboardAvoidingView, FlatList, Linking } from 'react-native';
import { useTheme } from '@config';
import { SafeAreaView, Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor, BaseStyle } from '../../config';
import { useFocusEffect } from '@react-navigation/native';
import { Button, Header, TextInput } from '../../components';
import * as SplashScreen from 'expo-splash-screen';

//import StoreListItem from '../../components/StoreListItem';
import { TouchableOpacity } from 'react-native';
import { compareDate, formatDate, formatDate2 } from '../../helpers/functions';
import Menu from '../../components/Menu';
import { Alert } from 'react-native';
import { actionActions, userActions } from '../../redux/actions';
import StoreListItem from '../../components/StoreListItem';
import { proxy } from '../../helpers/proxy';
import { exportWithFiles } from '../../helpers/apis';
import LoadingFullscreen from '../../components/LoadingFullscreen';

function StoreList({ getUserAuth, deleteAction, navigation, route, role, user }) {
    const { colors } = useTheme();
    const [state, setState] = useState({ all: [], action: { ...route.params.action, stores: [] }, loading: true, statusFilter: null, sort: 2, downloadProgress: 0 });
    const hasVisitedMarkets = state.action?.stores?.findIndex((store) => store.status > 0) >= 0;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const searchStore =
        state.action?.stores?.length > 0
            ? state.action?.stores
                .filter(
                    (store) =>
                        JSON.stringify(store)
                            .toLowerCase()
                            .includes((state.searchValue ?? '').toLowerCase()) &&
                        (state.statusFilter == 0
                            ? store.status == 0 || store.status == null
                            : state.statusFilter == null
                                ? true
                                : store.status == state.statusFilter)
                )
                .sort((a, b) => {
                    return state.sort == 1
                        ? a.name?.toString()?.localeCompare(b.name?.toString())
                        : state.sort == 2
                            ? a.postalCode?.toString()?.localeCompare(b.postalCode?.toString())
                            : state.sort == 3
                                ? (b.status ?? 0) - (a.status ?? 0)
                                : a.id?.toString()?.localeCompare(b.id?.toString());
                })
            : [];

    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    useFocusEffect(
        useCallback(() => {
            getUserAuth();
            onRefresh();
        }, [])
    );
    const searchText = (searchValue) => {
        setState((s) => ({ ...s, searchValue }));
    };
    const onRefresh = () => {
        actionActions.getSingleWR(`${state.action.id}?onlyStores=true${role == 3 ? `&userId=${user.id}` : ''}`, setState);
    };

    const deleteActionAlert = (id) => {
        Alert.alert({
            title: state.action?.name + ' löschen',
            message: 'Achtung!\nBeim Löschen werden alle Märktedaten und Märktebilder gelöscht',
            type: 'error',
            action: [
                { text: 'Löschen', onPress: () => deleteAction(id, navigation.goBack()) },
                { text: 'Abbrechen', onPress: () => { } },
            ],
        });
    };

    const exportAlert = (text) => {
        Alert.alert({
            title: 'Export',
            message: text,
            type: 'success',
            action: [{ text: 'Ok', onPress: () => { } }],
        });
    };

    const filterAction = () => (
        <View>
            <Menu
                activator={() => (
                    <View style={{ padding: 10, borderRadius: 0, backgroundColor: BaseColor.grayLight }}>
                        <Icon name="sort-ascending" type="mci" size={20} color={BaseColor.text} />
                    </View>
                )}
                actionItems={[
                    {
                        text: 'Nach Name',
                        action: () => {
                            setState((s) => ({ ...s, sort: 1 }));
                        },
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            ></View>
                        ),
                        rightIcon:
                            state.sort == 1
                                ? () => (
                                    <View
                                        style={{
                                            //backgroundColor: BaseColor.grayLight,
                                            height: 45,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="check" type="ad" size={20} color={BaseColor.primary} />
                                    </View>
                                )
                                : null,
                    },
                    {
                        text: 'Nach PLZ',
                        action: () => {
                            setState((s) => ({ ...s, sort: 2 }));
                        },
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            ></View>
                        ),
                        rightIcon:
                            state.sort == 2
                                ? () => (
                                    <View
                                        style={{
                                            //backgroundColor: BaseColor.grayLight,
                                            height: 45,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="check" type="ad" size={20} color={BaseColor.primary} />
                                    </View>
                                )
                                : null,
                    },
                    {
                        text: 'Nach Zustand',
                        action: () => {
                            setState((s) => ({ ...s, sort: 3 }));
                        },
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            ></View>
                        ),
                        rightIcon:
                            state.sort == 3
                                ? () => (
                                    <View
                                        style={{
                                            //backgroundColor: BaseColor.grayLight,
                                            height: 45,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="check" type="ad" size={20} color={BaseColor.primary} />
                                    </View>
                                )
                                : null,
                    },
                ]}
            />
        </View>
    );
    const adminAction = () => (
        <View style={{ top: 5, right: 5, position: 'absolute' }}>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                backgroundColor: BaseColor.whiteColor,
                                height: 40,
                                width: 40,
                                borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={
                    role == 1
                        ? [
                            {
                                text: 'Excel exportieren',
                                //textSecond: 'Demnächst',
                                leftIcon: () => (
                                    <View
                                        style={{
                                            backgroundColor: BaseColor.grayLight,
                                            height: 45,
                                            width: 45,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="download" type="ad" size={20} color={BaseColor.text} />
                                    </View>
                                ),
                                //disable: true,
                                action: () => {
                                    Linking.openURL(`${proxy}/export/${state.action.id}`);
                                },
                            },
                            {
                                text: 'Excel mit Bilder exportieren',
                                //textSecond: 'Demnächst',
                                leftIcon: () => (
                                    <View
                                        style={{
                                            backgroundColor: BaseColor.grayLight,
                                            height: 45,
                                            width: 45,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="folder-download-outline" type="mci" size={20} color={BaseColor.text} />
                                    </View>
                                ),
                                //disable: true,
                                action: () => {
                                    exportWithFiles(state.action.id, state.action.name, setState, exportAlert);
                                    //Linking.openURL(`${proxy}/exportWithFiles/${state.action.id}`);
                                },
                            },
                            {
                                text: 'Bearbeiten',
                                leftIcon: () => (
                                    <View
                                        style={{
                                            backgroundColor: BaseColor.grayLight,
                                            height: 45,
                                            width: 45,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="edit" type="ad" size={20} color={BaseColor.text} />
                                    </View>
                                ),
                                action: () => navigation.navigate({ name: 'ActionEdit', params: { action: state.action } }),
                            },
                            !hasVisitedMarkets || user.id == 19
                                ? {
                                    text: 'Löschen',
                                    leftIcon: () => (
                                        <View
                                            style={{
                                                backgroundColor: BaseColor.grayLight,
                                                height: 45,
                                                width: 45,
                                                borderRadius: 15,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                                        </View>
                                    ),
                                    action: () => deleteActionAlert(state.action.id),
                                }
                                : {},
                        ]
                        : [
                            {
                                text: 'Excel exportieren',
                                //textSecond: 'Demnächst',
                                leftIcon: () => (
                                    <View
                                        style={{
                                            backgroundColor: BaseColor.grayLight,
                                            height: 45,
                                            width: 45,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon name="download" type="ad" size={20} color={BaseColor.text} />
                                    </View>
                                ),
                                //disable: true,
                                action: () => {
                                    Linking.openURL(`${proxy}/export/${state.action.id}`);
                                },
                            },
                        ]
                }
            />
        </View>
    );
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);

    return (
        <View style={{ flex: 1, backgroundColor: BaseColor.grayLight }} onLayout={onLayoutRootView}>
            <Header
                title={state.action.name}
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
                renderRight={role == 1 || role == 2 ? adminAction : undefined}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <FlatList
                        contentContainerStyle={{ paddingBottom: 80 }}
                        refreshControl={
                            <RefreshControl colors={[colors.primary]} tintColor={colors.primary} refreshing={false} onRefresh={onRefresh} />
                        }
                        ListHeaderComponent={
                            <View>
                                <View
                                    style={{
                                        backgroundColor: BaseColor.whiteColor,
                                        padding: 15,
                                        paddingBottom: 0,
                                        borderBottomColor: BaseColor.grayTransparent,
                                        borderBottomWidth: 1,
                                    }}
                                >
                                    <View>
                                        <View>
                                            <View>
                                                <Text bold title3>
                                                    {state.action?.name}
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                {compareDate(currentDate, state.action?.endDate) >= 0 ? (
                                                    <Text caption1 errorColor>
                                                        Vorbei
                                                    </Text>
                                                ) : compareDate(state.action?.startDate) >= 0 ? (
                                                    <Text caption1 style={{ color: BaseColor.greenColor }}>
                                                        Läuft gerade
                                                    </Text>
                                                ) : (
                                                    <Text caption1 primaryColor>
                                                        Beginnt {formatDate(state.action?.startDate)}
                                                    </Text>
                                                )}
                                            </View>
                                        </View>
                                    </View>
                                    {state.action?.description && <Text>{state.action?.description}</Text>}
                                    <View style={{ alignItems: 'center', justifyContent: 'center', paddingTop: 5, flexDirection: 'row' }}>
                                        <View
                                            style={{
                                                paddingHorizontal: 10,
                                                paddingVertical: 5,
                                                borderRadius: 20,
                                                backgroundColor: BaseColor.grayLight,
                                            }}
                                        >
                                            <Text footnote>
                                                {state.action?.startDate && formatDate2(state.action?.startDate)} -{' '}
                                                {state.action?.startDate && formatDate2(state.action?.endDate)}
                                            </Text>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <View style={{ flex: 1 }}></View>
                                            <Text bold errorColor>
                                                {state.action?.stores?.filter((s) => !(s.status > 0)).length}
                                            </Text>
                                            <Text bold> | </Text>
                                            <Text bold style={{ color: BaseColor.yellowColor }}>
                                                {state.action?.stores?.filter((s) => s.status == 1).length}
                                            </Text>
                                            <Text bold> | </Text>
                                            <Text bold style={{ color: BaseColor.greenDark }}>
                                                {state.action?.stores?.filter((s) => s.status == 2).length}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingTop: 20 }}>
                                        <View style={{ flex: 1 }}>
                                            <TextInput
                                                onChangeText={searchText}
                                                placeholder="Suche nach Name, PLZ, Status..."
                                                value={state.searchValue}
                                                style={{ height: 40, borderRadius: 0 }}
                                                icon={
                                                    state.searchValue ? (
                                                        <TouchableOpacity onPress={() => searchText('')} style={{ padding: 5 }}>
                                                            <Icon name="times" type="mui" size={20} color={BaseColor.text} />
                                                        </TouchableOpacity>
                                                    ) : (
                                                        <Icon name="search" type="fa" size={20} color={BaseColor.text} />
                                                    )
                                                }
                                            />
                                        </View>
                                        <View style={{ paddingLeft: 10, alignItems: 'center', justifyContent: 'center' }}>{filterAction()}</View>
                                    </View>
                                    <View style={{ flexDirection: 'row', paddingTop: 10 }}>
                                        <View>
                                            <TouchableOpacity
                                                style={{
                                                    //backgroundColor: BaseColor.grayLight,
                                                    height: 40,
                                                    paddingHorizontal: 3,
                                                    //borderRadius: 20,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderBottomWidth: state.statusFilter == null ? 2 : 0,
                                                    borderColor: BaseColor.primary,
                                                }}
                                                onPress={() => setState((s) => ({ ...s, statusFilter: null }))}
                                            >
                                                <View>
                                                    <Text>Alle</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <TouchableOpacity
                                                style={{
                                                    // backgroundColor: BaseColor.grayLight,
                                                    height: 40,
                                                    //borderRadius: 20,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderBottomWidth: state.statusFilter == 0 ? 2 : 0,
                                                    borderColor: BaseColor.primary,
                                                }}
                                                onPress={() => setState((s) => ({ ...s, statusFilter: 0 }))}
                                            >
                                                <View>
                                                    <Text>Unbesucht</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <TouchableOpacity
                                                style={{
                                                    // backgroundColor: BaseColor.grayLight,
                                                    height: 40,
                                                    //borderRadius: 20,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderBottomWidth: state.statusFilter == 1 ? 2 : 0,
                                                    borderColor: BaseColor.primary,
                                                }}
                                                onPress={() => setState((s) => ({ ...s, statusFilter: 1 }))}
                                            >
                                                <View>
                                                    <Text>Besucht</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <TouchableOpacity
                                                style={{
                                                    //backgroundColor: BaseColor.grayLight,
                                                    height: 40,
                                                    //borderRadius: 20,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderBottomWidth: state.statusFilter == 2 ? 2 : 0,
                                                    borderColor: BaseColor.primary,
                                                }}
                                                onPress={() => setState((s) => ({ ...s, statusFilter: 2 }))}
                                            >
                                                <View>
                                                    <Text>Bestätigt</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ padding: 15 }}>
                                    <Text headline bold>
                                        {role == 3 ? 'Meine ' : ''}Märkte
                                    </Text>
                                </View>
                            </View>
                        }
                        data={!state.action?.stores?.length > 0 && state.loading ? [1, 2, 3, 4, 5, 6] : searchStore ?? []}
                        renderItem={({ item }) => (
                            <View style={{ paddingHorizontal: 15 }}>
                                {item?.id ? (
                                    <StoreListItem navigation={navigation} item={item} clickable={role == 2 ? item.status == 2 : true} />
                                ) : (
                                    <StoreListItem loading={true} />
                                )}
                            </View>
                        )}
                        keyExtractor={(item) => `ClientListItem ${item?.id ?? item}`}
                    />
                    {role == 1 && (
                        <View style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                            <View style={{ padding: 15, alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    style={{ height: 40, backgroundColor: 'white' }}
                                    textColor={'black'}
                                    icon={<Icon name="plus" size={25} type="ad" color={BaseColor.primary} />}
                                    onPress={() => navigation.navigate('StoreNew', { actionId: state.action.id })}
                                >
                                    {' '}
                                    Neuer Markt anlegen
                                </Button>
                            </View>
                        </View>
                    )}
                </KeyboardAvoidingView>
            </SafeAreaView>
            {state.exporting && (
                <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <LoadingFullscreen text={state.progress < 100 ? `Exportdatei wird erstellt... ${state.progress}%` : `Bitte warten, Exportdatei wird heruntergeladen...${state.downloadProgress}%`} />
                </View>
            )}
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    return { role: user?.roles[0], user };
}

const storeCreators = {
    getUserAuth: userActions.getUserAuth,
    getSingleAction: actionActions.getSingle,
    deleteAction: actionActions.delete,
};

export default connect(mapState, storeCreators)(StoreList);
