import React, { useEffect, useRef } from 'react';
import { Animated, TouchableOpacity, Vibration, View } from 'react-native';
import { Icon, Text } from '../../components';
import { BaseColor } from '../../config';
import { scrollToId } from '../../helpers/functions';

export default function FormCheckBoxInput({ submitedInput, setInput, isLast, editable }) {
    const animationRef = useRef(new Animated.Value(0));
    const values = submitedInput?.value?.split(';') ?? [];
    const highlight = () => {
        scrollToId('submitedInput' + submitedInput.id);
        Vibration.vibrate(100);
        Animated.sequence([
            Animated.timing(animationRef.current, {
                toValue: 1,
                // set the duration
                duration: 500,
                useNativeDriver: false,
            }),
            // delay is optional
            Animated.delay(1000),
            Animated.timing(animationRef.current, {
                toValue: 0,
                duration: 500,
                // useNativeDriver:true, might not work with the all properties that you need to animate. true might improve animation performance
                useNativeDriver: false,
            }),
        ]).start();
    };
    const check = (item) => {
        let selected = values.indexOf(item.value) > -1;
        let valuesAsArray = values;
        if (selected) {
            valuesAsArray = valuesAsArray.filter((value) => value != item.value);
        } else {
            valuesAsArray.push(item.value);
        }
        let newVallue = '';
        valuesAsArray.forEach((value) => {
            newVallue += (newVallue ? ';' : '') + value;
        });
        setInput({
            value: newVallue,
            error: false,
        });
    };
    useEffect(() => {
        if (editable && submitedInput.error) highlight();
    }, [submitedInput.checkInput]);

    return (
        <Animated.View
            style={{
                borderBottomColor: BaseColor.grayTransparent,
                borderBottomWidth: isLast ? 0 : 1,
                paddingBottom: 15,
                paddingTop: 10,
                paddingHorizontal: 15,
                backgroundColor: animationRef.current?.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['#fff', '#ccc'],
                }),
            }}
        >
            <div id={'submitedInput' + submitedInput.id}>
                <View style={{ paddingBottom: 5 }}>
                    <Text>
                        {submitedInput.input?.label}
                        {submitedInput.input?.required && ' *'}
                    </Text>

                    {submitedInput.input?.description && (
                        <Text grayColor footnote>
                            {submitedInput?.input.description}
                        </Text>
                    )}
                </View>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {submitedInput.input?.items.map((item, index) => (
                        <TouchableOpacity
                            activeOpacity={editable ? 0.5 : 1}
                            key={item.value + index}
                            onPress={() => editable && check(item)}
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                paddingVertical: 10,
                                paddingRight: 15,
                            }}
                        >
                            <View style={{ paddingRight: 5 }}>
                                <Icon
                                    name={values.indexOf(item.value) > -1 ? 'checkcircle' : 'circle'}
                                    type={values.indexOf(item.value) > -1 ? 'ad' : 'ft'}
                                    size={22}
                                    enableRTL={true}
                                />
                            </View>
                            <View>
                                <Text>{item.value}</Text>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
                {submitedInput?.error && (
                    <Text errorColor footnote>
                        Feld erforderlich
                    </Text>
                )}
            </div>
        </Animated.View>
    );
}
