import { authHeader, proxy } from '@helpers';

export const storeService = {
    create,
    getAll,
    getById,
    update,
    delete: _delete,
};

async function create(store) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(store),
    };

    const response = await fetch(`${proxy}/stores`, requestOptions);
    return handleResponse(response);
}

async function getAll(filter) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const response = await fetch(`${proxy}/stores${filter ? filter : ''}`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${proxy}/stores/${id}`, requestOptions);
    return handleResponse(response);
}

async function update(store) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(store),
    };

    const response = await fetch(`${proxy}/stores`, requestOptions);
    return handleResponse(response);
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify({ id }),
    };

    const response = await fetch(`${proxy}/stores/${id}`, requestOptions);
    return handleResponse(response);
}

async function handleResponse(response) {
    return await response.text().then((text) => {
        let data = text;
        try {
            data = text && JSON.parse(text);
        } catch (error) {
            console.error('cannot convert String to JSON', error);
        }
        if (!response.ok) {
            if (response.status == 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
