import { postConstants } from '../constants';

let initialState = {
    all: {},
    allContents: [],
    allMore: {},
    favorites: {},
    moreFavorites: {},
    myPosts: {},
    myPostsMore: {},
    singles: {},
    similars: {},
    newests: {},
    mostWanteds: {},
    tops: {},
    urgents: {},
    current: {},
    currents: {},
};
export function posts(state = initialState, action) {
    let currentResult = state.currents;
    switch (action.type) {
        case 'INIT':
            return  initialState;
        case postConstants.GETALL_REQUEST:
            return {
                ...state,
                all: { loading: true },
                allMore: {},
                allContents: [],
            };
        case postConstants.GETALL_SUCCESS:
            action.all.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                all: { [action.all.number]: action.all },
                allContents: [...state.allContents, ...action.all.content],
                currents: currentResult,
            };
        case postConstants.GETALL_FAILURE:
            return {
                ...state,
                all: { ...state.all, loading: false, error: action.error },
            };

        case postConstants.GETALLMORE_REQUEST:
            return {
                ...state,
                allContents: state.allMore.content ? [...state.allContents, ...state.allMore.content] : state.allContents,
                //allContents: state.allMore.content ? [...state.allContents, ...state.allMore.content] : state.allContents,
                allMore: { ...state.allMore, loading: true },
            };
        case postConstants.GETALLMORE_SUCCESS:
            action.allMore.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                all: { ...state.all, [action.allMore.number]: action.allMore },
                allMore: action.allMore,

                currents: currentResult,
            };
        case postConstants.GETALLMORE_FAILURE:
            return {
                ...state,
                allMore: { ...state.allMore, loading: false, error: action.error },
            };

        case postConstants.GETFAVORITE_REQUEST:
            return {
                ...state,
                favorites: { ...state.favorites, loading: true },
            };
        case postConstants.GETFAVORITE_SUCCESS:
            action.favorites.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                favorites: action.favorites,
                moreFavorites: action.favorites,
                currents: currentResult,
            };
        case postConstants.GETFAVORITE_FAILURE:
            return {
                ...state,
                favorites: { ...state.favorites, loading: false, error: action.error },
            };

        case postConstants.GETMOREFAVORITE_REQUEST:
            return {
                ...state,
                moreFavorites: { ...state.moreFavorites, loading: true },
            };
        case postConstants.GETMOREFAVORITE_SUCCESS:
            action.moreFavorites.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                favorites: { ...state.favorites, content: state.favorites.content.concat(action.moreFavorites.content) },
                moreFavorites: action.moreFavorites,
                current: currentResult,
            };
        case postConstants.GETMOREFAVORITE_FAILURE:
            return {
                ...state,
                moreFavorites: { error: action.error },
            };

        case postConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                myPosts: { ...state.myPosts, deleting: true },
            };
        case postConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                myPosts: { ...state.myPosts, deleting: false, content: state.myPosts.content.filter((post) => action.ids.indexOf(post.id) < 0) },
            };
        case postConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                myPosts: {
                    ...state.myPosts,
                    deleting: false,
                    deletingError: action.error,
                },
            };

        case postConstants.CREATE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: { ...state.items, content: state.items.content?.map((post) => (post.id == action.id ? { ...post, creating: true } : post)) },
            };
        case postConstants.CREATE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                items: { ...state.items, content: state.items.content.push(action.post) },
            };
        case postConstants.CREATE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                items: {
                    ...state.item,
                    content: state.items.content?.map((post) => {
                        if (post.id == action.id) {
                            // make copy of user without 'deleting:true' property
                            const { creating, ...postCopy } = post;
                            // return copy of user with 'deleteError:[error]' property
                            return { ...state, ...postCopy, createError: action.error };
                        }
                    }),
                },
            };

        case postConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: {
                    ...state.items,
                    content: state.items.content?.map((post) => (post.id == action.post.id ? { ...post, updating: true } : post)),
                },
            };
        case postConstants.UPDATE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                // items: {
                //     ...state.items,
                //     content: state.items.content?.map((post) => (post.id == action.post.id ? { ...action.post, updating: false } : post)),
                // },
                myPosts: {
                    ...state.myPosts,
                    content: state.myPosts.content?.map((post) => (post.id == action.post.id ? { ...action.post, updating: false } : post)),
                },
            };
        case postConstants.UPDATE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                items: {
                    ...state.items,
                    content: state.items.content?.map((post) => {
                        if (post.id == action.post.id) {
                            // make copy of user without 'deleting:true' property
                            const { updating, ...postCopy } = post;
                            // return copy of user with 'deleteError:[error]' property
                            return { ...state, ...postCopy, updatingError: action.error };
                        }
                    }),
                },
            };

        case postConstants.GETBYID_REQUEST:
            return state.singles[action.postId]
                ? {
                      ...state,
                      singles: { ...state.singles, [action.postId]: { ...state.singles[action.postId], loading: true } },
                  }
                : {
                      ...state,
                      singles: { ...state.singles, [action.postId]: { loading: true } },
                  };
        case postConstants.GETBYID_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                singles: { ...state.singles, [action.postId]: action.single },
            };
        case postConstants.GETBYID_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                singles: { ...state.singles, [action.postId]: { error: action.error, loading: false } },
            };

        case postConstants.GETBYIDSIMILAR_REQUEST:
            return {
                ...state,
                similars: { ...state.similars, [action.postId]: { loading: true } },
            };
        case postConstants.GETBYIDSIMILAR_SUCCESS:
            // remove deleted user from state
            action.similar.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                similars: { ...state.similars, [action.postId]: action.similar },
                currents: currentResult,
            };
        case postConstants.GETBYIDSIMILAR_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                similars: { ...state.similars, [action.postId]: { error: action.error, loading: false } },
            };

        case postConstants.GETALLTOP_REQUEST:
            return {
                ...state,
                tops: { ...state.tops, loading: true },
            };
        case postConstants.GETALLTOP_SUCCESS:
            action.tops.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                tops: action.tops,
                currents: currentResult,
            };
        case postConstants.GETALLTOP_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                tops: { ...state.tops, error: action.error, loading: false },
            };
        case postConstants.GETALLURGENT_REQUEST:
            return {
                ...state,
                urgents: { ...state.urgents, loading: true },
            };
        case postConstants.GETALLURGENT_SUCCESS:
            // remove deleted user from state
            action.urgents.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                urgents: action.urgents,
                currents: currentResult,
            };
        case postConstants.GETALLURGENT_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                urgents: { ...state.urgents, error: action.error, loading: false },
            };
        case postConstants.GETALLNEWEST_REQUEST:
            return {
                ...state,
                newews: { ...state.newests, loading: true },
            };
        case postConstants.GETALLNEWEST_SUCCESS:
            // remove deleted user from state
            action.newests.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                newests: action.newests,
                currents: currentResult,
            };
        case postConstants.GETALLNEWEST_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                newests: { ...state.newests, error: action.error, loading: false },
            };

        case postConstants.GET_MOSTWANTED_REQUEST:
            return {
                ...state,
                mostWanteds: { ...state.mostWanteds, loading: true },
            };
        case postConstants.GET_MOSTWANTED_SUCCESS:
            // remove deleted user from state
            action.mostWanteds.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                mostWanteds: action.mostWanteds,
                currents: currentResult,
            };
        case postConstants.GET_MOSTWANTED_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                mostWanteds: { ...state.mostWanteds, error: action.error, loading: false },
            };

        ///////////////////////////////

        case postConstants.GET_MYPOSTS_REQUEST:
            return {
                ...state,
                myPosts: { ...state.myPosts, loading: true },
            };
        case postConstants.GET_MYPOSTS_SUCCESS:
            // remove deleted user from state
            action.myPosts.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                myPosts: action.myPosts,
                myPostsMore: action.myPosts,
                currents: currentResult,
            };
        case postConstants.GET_MYPOSTS_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                myPosts: { ...state.myPosts, error: action.error, loading: false },
            };

        case postConstants.GETMORE_MYPOSTS_REQUEST:
            return {
                ...state,
                myPostsMore: { ...state.myPostsMore, loading: true },
            };
        case postConstants.GETMORE_MYPOSTS_SUCCESS:
            // remove deleted user from state
            action.myPostsMore.content.forEach((p) => {
                currentResult = { ...currentResult, [p.id]: p };
            });
            return {
                ...state,
                myPosts: { ...state.myPosts, content: state.myPosts.content.concat(action.myPostsMore.content) },
                myPostsMore: action.myPostsMore,
                currents: currentResult,
            };
        case postConstants.GETMORE_MYPOSTS_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                myPostsMore: { error: action.error, loading: false },
            };
        case postConstants.CHANGE_CURRENT:
            return { ...state, current: action.current };
        case postConstants.ADD_TO_FAVORITE_REQUEST:
            return {
                ...state,

                /*myPosts: state.myPosts.content
                    ? { ...state.myPosts, content: state.myPosts.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: true } : p)) }
                    : state.myPosts,*/
                singles: state.singles[action.postId]
                    ? { ...state.singles, [action.postId]: { ...state.singles[action.postId], inFavoriteOf: true } }
                    : state.singles,
                /*similars: state.similars[action.postId]
                    ? {
                          ...state.similars,
                          [action.postId]: {
                              ...state.similars[action.postId],
                              content: state.similars[action.postId].content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: true } : p)),
                          },
                      }
                    : state.similars,
                newests: state.newests.content
                    ? { ...state.newests, content: state.newests.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: true } : p)) }
                    : state.newests,
                mostWanteds: state.mostWanteds.content
                    ? {
                          ...state.mostWanteds,
                          content: state.mostWanteds.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: true } : p)),
                      }
                    : state.mostWanteds,
                tops: state.tops.content
                    ? { ...state.tops, content: state.tops.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: true } : p)) }
                    : state.tops,
                favorites: state.favorites.content
                    ? {
                          ...state.favorites,
                          content: state.favorites.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: true } : p)),
                      }
                    : state.favorites,*/
            };
        case postConstants.REMOVE_TO_FAVORITE_REQUEST:
            return {
                ...state,
                /*myPosts: state.myPosts.content
                    ? { ...state.myPosts, content: state.myPosts.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: false } : p)) }
                    : state.myPosts,*/
                singles: state.singles[action.postId]
                    ? { ...state.singles, [action.postId]: { ...state.singles[action.postId], inFavoriteOf: false } }
                    : state.singles,
                /*similars: state.similars[action.postId]
                    ? {
                          ...state.similars,
                          [action.postId]: {
                              ...state.similars[action.postId],
                              content: state.similars[action.postId].content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: false } : p)),
                          },
                      }
                    : state.similars,
                newests: state.newests.content
                    ? { ...state.newests, content: state.newests.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: false } : p)) }
                    : state.newests,
                mostWanteds: state.mostWanteds.content
                    ? {
                          ...state.mostWanteds,
                          content: state.mostWanteds.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: false } : p)),
                      }
                    : state.mostWanteds,
                tops: state.tops.content
                    ? { ...state.tops, content: state.tops.content?.map((p) => (p.id == action.postId ? { ...p, inFavoriteOf: false } : p)) }
                    : state.tops,*/
                favorites: state.favorites.content
                    ? {
                          ...state.favorites,
                          content: state.favorites.content.filter((p) => p.id != action.postId),
                      }
                    : state.favorites,
            };
        case postConstants.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}
