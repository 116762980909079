import { storeConstants } from '../constants';

let initialState = {
    all: {},
};
export function stores(state = initialState, action) {
    switch (action.type) {
        case 'INIT':
            return initialState;

        case storeConstants.DELETE_SUCCESS:
            let { [action.id]: all, ...rest } = state.all;
            return {
                ...state,
                all: rest,
            };

        case storeConstants.CREATE_SUCCESS:
            return {
                ...state,
                all: { ...state.all, [action.store.id]: action.store },
                creating: false,
            };

        case storeConstants.UPDATE_SUCCESS:
            return {
                ...state,
                all: { ...state.all, [action.store.id]: action.store },
            };

        case storeConstants.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}
