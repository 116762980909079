import { offlineStorageConstants } from '../constants';

const initialState = {
    newPostState: {
        type: false,
        option: 'Prix fix',
        images: [],
    },
    newPostSelectedImages: [],
    albumImages: [],
};

export function offlineStorage(state = initialState, action) {
    switch (action.type) {
        case 'INIT':
            return { ...initialState, device: state.device };
        case offlineStorageConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                newPostState: {
                    type: false,
                    option: 'Prix fix',
                    images: [],
                },
                newPostSelectedImages: [],
                albumImages: [],
            };

        case offlineStorageConstants.CHANGE_LR:
            return {
                ...state,
                lr: action.lr,
            };
        case offlineStorageConstants.UPDATE_NEW_POST_STATE:
            return { ...state, newPostState: action.newPostState };
        case offlineStorageConstants.SELECT_NEW_POST_IMAGE:
            state.newPostSelectedImages.push(action.selectedImage);
            return state;
        case offlineStorageConstants.UNSELECT_NEW_POST_IMAGE:
            return {
                ...state,
                newPostSelectedImages: state.newPostSelectedImages.filter((image) => image.id.localeCompare(action.selectedImage.id) != 0),
            };
        case offlineStorageConstants.UNSELECT_ALL_NEW_POST_IMAGE:
            return {
                ...state,
                newPostSelectedImages: [],
            };
        case offlineStorageConstants.ADD_TO_NEW_POST_IMAGE:
            return {
                ...state,
                newPostState: {
                    ...state.newPostState,
                    images: state.newPostState.images.concat(state.newPostSelectedImages),
                },
            };
        case offlineStorageConstants.REMOVE_TO_NEW_POST_IMAGE:
            return {
                ...state,
                newPostState: {
                    ...state.newPostState,
                    images: state.newPostState.images.filter((image) => image.id.localeCompare(action.selectedImage.id) != 0),
                },
            };
        case offlineStorageConstants.UPDATE_NEW_POST_IMAGE:
            return {
                ...state,
                newPostState: {
                    ...state.newPostState,
                    images: state.newPostState.images.map((image) =>
                        image.id.localeCompare(action.selectedImage.id) == 0 ? action.selectedImage : image
                    ),
                },
            };
        case offlineStorageConstants.PUT_TO_COVER:
            return {
                ...state,
                newPostState: {
                    ...state.newPostState,
                    images: [action.selectedImage].concat(
                        state.newPostState.images.filter((image) => image.id.localeCompare(action.selectedImage.id) != 0)
                    ),
                },
            };
        case offlineStorageConstants.SAVE_ALBUMS:
            return {
                ...state,
                albums: action.albums,
            };
        case offlineStorageConstants.SAVE_ALBUM_IMAGES:
            return {
                ...state,
                albumImages: { ...albumImages, [action.albumId]: action.albumImages },
            };
        case offlineStorageConstants.UPDATE_EXPOPUSHTOKEN:
            return {
                ...state,
                expoPushToken: action.expoPushToken,
            };
        case offlineStorageConstants.SIMPLE_UPDATE:
            return {
                ...state,
                ...action.value,
            };
        default:
            return state;
    }
}
