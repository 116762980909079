import { StyleSheet } from 'react-native';
import { BaseColor } from '@config';

export default StyleSheet.create({
    contain: {
        //paddingVertical: 10,
        //paddingHorizontal: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    line: {
        width: 1,
        height: 14,
        backgroundColor: BaseColor.grayColor,
        marginLeft: 10,
    },
    contentModeView: {
        width: 30,
        height: '100%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    contentFilter: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
    },
    bottomModal: {
        justifyContent: 'flex-start',
        margin: 0,
    },
    contentFilterBottom: {
        width: '100%',
    },
    contentSwipeDown: {
        paddingVertical: 15,
        alignItems: 'center',
    },
    lineSwipeDown: {
        width: 35,
        height: 4,
        borderRadius: 5,
        backgroundColor: BaseColor.grayColor,
    },
    contentActionModalBottom: {
        flexDirection: 'row',
        paddingVertical: 15,
        justifyContent: 'space-between',
        borderBottomWidth: 1,
    },
});
