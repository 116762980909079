import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Icon } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { Button } from '../../components';
import Menu from '../../components/Menu';
import { clientActions, userActions } from '../../redux/actions';
import FormTextInput from './FormTextInput';
import FormDropdownInput from './FormDropdownInput';
import FormCheckBoxInput from './FormCheckBoxInput';
import FormUploaderInput from './FormUploaderInput';

const inputTypes = [
    { type: 'Text', required: null, editable: true, label: null, placeholder: null, description: null, icon: { name: 'text', type: 'ii' } },
    {
        type: 'Dropdown',
        required: null,
        editable: true,
        label: null,
        placeholder: null,
        description: null,
        items: [],
        icon: { name: 'menufold', type: 'ad' },
    },
    {
        type: 'CheckBox',
        required: null,
        editable: true,
        label: null,
        placeholder: null,
        description: null,
        items: [],
        icon: { name: 'checkcircleo', type: 'ad' },
    },
    {
        type: 'Uploader',
        required: null,
        editable: true,
        label: null,
        placeholder: null,
        description: null,
        items: [],
        icon: { name: 'upload', type: 'ad' },
    },
];

function Form({ navigation, createUser, section, setSection, deleteSection, checkSumbition }) {
    const [state, setState] = useState({
        ...section,
    });
    const [editing, setEditing] = useState(null);
    const [checkAllInputs, setCheckAllInputs] = useState(false);
    const [checkEditableInput, setCheckEditableInput] = useState(false);
    const checkEditing = (value) => {
        if (editing == null || value == null || value == editing) {
            setEditing(value);
            return false;
        } else {
            setCheckEditableInput((s) => !s);
            return true;
        }
    };
    const setForm = (name, value) => {
        if (name == 'startDate' || name == 'EndDate') {
            value.setSeconds(0);
        }
        setState((s) => ({ ...s, [name]: value }));
    };

    function checkSectionSumbition() {
        setCheckAllInputs((s) => !s);
        //let submitable = state.inputs?.filter((input) => !input.submitable).length == 0;
    }

    useEffect(() => {
        checkSectionSumbition();
    }, [checkSumbition]);

    const renderInput = () => {
        return (
            <View>
                {state.inputs.map((input) => {
                    let Component;
                    switch (input.type) {
                        case 'Text':
                            Component = FormTextInput;
                            break;
                        case 'Dropdown':
                            Component = FormDropdownInput;
                            break;
                        case 'CheckBox':
                            Component = FormCheckBoxInput;
                            break;
                        case 'Uploader':
                            Component = FormUploaderInput;
                            break;
                        default:
                            break;
                    }
                    return (
                        <View key={input.order}>
                            <Component
                                input={input}
                                setInput={(newInput) => {
                                    setSection({ inputs: state.inputs.map((i) => (i.order == input.order ? { ...i, ...newInput } : i)) });
                                    setState((s) => ({ ...s, inputs: s.inputs.map((i) => (i.order == input.order ? { ...i, ...newInput } : i)) }));
                                }}
                                editing={editing}
                                deleteInput={() => {
                                    setSection({ inputs: state.inputs.filter((i) => i.order != input.order) });
                                    setState((s) => ({ ...s, inputs: s.inputs.filter((i) => i.order != input.order) }));
                                }}
                                checkAllInputs={checkAllInputs}
                                checkEditableInput={checkEditableInput}
                                checkEditing={checkEditing}
                            />
                        </View>
                    );
                })}
            </View>
        );
    };

    return (
        <View
            style={{
                marginBottom: 15,
                backgroundColor: BaseColor.whiteColor,
                borderBottomColor: BaseColor.grayTransparent,
                borderBottomWidth: 1,
            }}
        >
            <View style={{ alignItems: 'flex-end', padding: 15, paddingBottom: 10 }}>
                <TouchableOpacity
                    style={[
                        {
                            backgroundColor: BaseColor.grayLight,
                            height: 40,
                            width: 40,
                            borderRadius: 15,
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        //Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                    ]}
                    onPress={deleteSection}
                >
                    <Icon name="delete" type="ad" size={20} enableRTL={true} />
                </TouchableOpacity>
            </View>
            {renderInput()}
            <View style={{ alignItems: 'center', paddingHorizontal: 15, paddingBottom: 15 }}>
                {editing ? (
                    <Button
                        full
                        onPress={() => checkEditing(-1)}
                        loading={state.creating}
                        style={{ height: 40, backgroundColor: BaseColor.grayColor, borderRadius: 0 }}
                        //textColor={'black'}
                        icon={<Icon name="plus" size={20} type="ad" color={BaseColor.whiteColor} />}
                    >
                        {' '}
                        Feld hinzufügen
                    </Button>
                ) : (
                    <Menu
                        activatorStyle={{ width: '100%' }}
                        activator={() => (
                            <Button
                                full
                                unTouchable
                                loading={state.creating}
                                style={{ height: 40, backgroundColor: BaseColor.grayColor, borderRadius: 0 }}
                                //textColor={'black'}
                                icon={<Icon name="plus" size={20} type="ad" color={BaseColor.whiteColor} />}
                            >
                                {' '}
                                Feld hinzufügen
                            </Button>
                        )}
                        actionItems={inputTypes?.map((input) => ({
                            text: input.type,
                            leftIcon: () => (
                                <View
                                    style={[
                                        {
                                            backgroundColor: BaseColor.grayLight,
                                            height: 40,
                                            width: 40,
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        },
                                        //Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                                    ]}
                                >
                                    <Icon name={input.icon.name} type={input.icon.type} size={20} enableRTL={true} />
                                </View>
                            ),
                            action: () =>
                                setState((s) => ({
                                    ...s,
                                    inputOrder: s.inputOrder + 1,
                                    inputs: [...s.inputs, { ...input, istNew: true, order: s.inputOrder + 1 }],
                                })),
                        }))}
                    />
                )}
            </View>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { all } = state.clients;
    const { creating } = state.users.all;
    return { user, creating, allClient: all };
}

const actionCreators = {
    createUser: userActions.register,
    getAllClient: clientActions.getAll,
};

export default connect(mapState, actionCreators)(Form);
