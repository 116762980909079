import React from 'react';
import { View } from 'react-native';
import Icon from '../Icon';
import Text from '../Text';
import styles from './styles';
import { BaseColor } from '@config';
import { BaseStyle } from '../../config';

export default function ProfileDetail(props) {
    const { styleLeft, user, textSecond } = props;

    return (
        <View>
            <View style={{ flexDirection: 'row', backgroundColor: '#fff', padding: 20, borderRadius: 15, ...BaseStyle.boxWithShadowBottom }}>
                <View style={[styles.contentLeft, styleLeft]}>
                    <View>
                        <View style={[styles.thumb, { backgroundColor: BaseColor.grayTransparent, justifyContent: 'center' }]}>
                            <Icon name="user" size={30} solid type="ad" style={{ alignSelf: 'center' }} enableRTL={true} />
                        </View>
                    </View>
                    <View style={{ paddingRight: 10 }}>
                        <Text headline semibold numberOfLines={1}>
                            {user.firstname + ' ' + user.lastname}
                        </Text>
                        <Text footnote numberOfLines={2}>
                            {textSecond}
                        </Text>
                        <Text footnote numberOfLines={2}>
                            {user.email}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
}
